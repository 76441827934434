import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AttachmentsManagementService {
  url: string = environment.attachmentsUrl + 'attachments';
  constructor(private _http: HttpClient) {}

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    return this._http.post(this.url, formData).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
