import { Component, OnInit } from '@angular/core';
import { EventTypes } from 'src/app/models/toast';
import { ApplicationsService } from 'src/app/services/applications.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: "app-applications",
  templateUrl: "./applications.component.html",
  styleUrls: ["./applications.component.scss"],
})
export class ApplicationsComponent implements OnInit {
  applications: any[] = [];
  constructor(
    private _applicationsService: ApplicationsService,
    private _toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this._applicationsService.getApplications().subscribe(
      (result: any) => {
        if (result) {
          this.applications = result.map((application: any) => {
            const formattedDate = this.formatDate(application.created_at);
            return { ...application, created_at: formattedDate };
          });
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options : Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
}
