
<p-toast></p-toast>
<div
  class="d-flex m-3 justify-content-center align-items-center p-4"
>
  <div class="card d-flex rounded flex-row">
    <div
      class="card-body card-img-cont d-flex flex-column text-center justify-content-center d-none d-md-flex"
    >
      <img
        src="https://res.cloudinary.com/duvzjjfba/image/upload/v1681994723/buildings_2_g60ltj.svg"
        alt="..."
        style="height: 35%"
      />
      <br />
      <h4 class="text-white">
        Take Control of Your Building <br />
        Permits with Kubaka
      </h4>
    </div>
    <div class="card-body" style="text-align: middle;">
      <form #form="ngForm" class="d-flex flex-column" style="height:100%;justify-content: center;" (ngSubmit)="signin(form.value)">
        <h5 class="card-title" style="text-align: center;">Welcome back, Login into your account</h5>
        <div class="mt-2">
          <label for="email-input" class="form-label"
            >Email address/Phone number</label
          >
          <input
            type="email"
            class="form-control"
            id="email-input"
            ngModel
            name="email_or_phone"
            #email="ngModel"
            placeholder="eg: example@email.com"
            aria-describedby="emailHelp"
            required
          />
          <div *ngIf="email.touched && !email.valid">
            <p class="error" *ngIf="email?.errors?.['required']">email is required</p>
          </div>
        </div>
        <div class="mt-2">
          <label for="password-input" class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            ngModel
            name="password"
            #password="ngModel"
            id="password-input"
            required
          />
        </div>
        <div
          *ngIf="password.touched && !password.valid"
        >
          <p class="error" *ngIf="password?.errors?.['required']">Password is required</p>
        </div>
        <div class="submit text-center mt-2">
          <button
            type="submit"
            class="btn btn-primary align-items-center"
            style="width: 100%;"
            [disabled]="!form.valid || loggingIn"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="loggingIn; else default"
            ></span>
            <ng-template #default> Login </ng-template></button
          ><br />
          <p class="mt-1">
            <a class="text-primary" routerLink="/forgot">Forgot password?</a>
          </p>
          <p class="mt-1">
            Dont have an account?
            <a
              class="text-primary"
              routerLink="/signup"
              style="text-decoration: none"
              >Signup</a
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
