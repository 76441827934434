import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class CountriesService {
  url = environment.countriesAPIUrl as string;

  constructor(private _http: HttpClient) {}

  getCountriesAndCodes() {
    return this._http.get(this.url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
