export class KinyarwandaResource{
      // GENERAL
      general_login_text = "Injira"
      // - GENERAL

      // Main Page
      main_page_title = "Ibyangombwa Byo Kubaka";
      main_page_subtitle = "Kurikiranira hano aho ibyangombwa byawe byo kubaka bigeze";
      main_upi_search_placeholder = "Shakisha ukoresheje UPI";
      main_search_button = "Shakisha"
      main_spinner_text = "Mube mutegereje gato..."
 
      main_current_status_text = "Aho ubusabe bugeze";

    //--Results story
    results_date_specifier = "Ku itariki "
    results_permit_type = "wasabye ibyangombwa byo kubaka byitwa ";
    results_permit_sector="muri segiteri";
    results_application_number_text="ubusabe bukaba bufite nimero "
    results_application_stage_text = "Ubusabe bwawe bugeze ku gice cyitwa "
    results_application_not_submitted_text = "Ntabwo urohereza ubusabe bwawe. Ohereza ubusabe bwawe ukoresheje uburyo busanzwe, ubundi utangire ukurikiranire hano aho bugeze.";
    results_permit_approved_text="Ubusabe bwawe bwaremewe ku itariki "
    results_permit_number_text = "ibyangombwa byawe bikaba bifite nimero "
    results_login_to_download = "muri konti yawe urabusangamo.";
    //!--Results stage

    // -- Application Stages
    application_stage_tittle="Ibyiciro ubusabe bunyuramo"
    application_stage_submission = "Gutanga ubusabe"
    application_stage_submission_text = "Iki ni ikiciro cyambere igihe uba umaze kohereza ubusabe."
    application_stage_review = "Igenzura"
    application_stage_review_text = "Hano, ubusabe bwawe buba buri kugenzurwa n'abakozi bashinzwe gutanga ibyangombwa."
    application_stage_architect = "Byasubiye kuri enjeniyeri"
    application_stage_architect_text = "Aha haba hari ikibazo cyagaragaye kw'idosiye yawe, none enjeniyeri akaba asabwa gukosora ibibazo birimo ubundi akongera akohereza ubusabe."
    application_stage_invoicing = "Kwishyura"
    application_stage_invoicing_text = "Aha ubutumwa bugufi buba bwamaze koherezwa kuri telephone ya enjeniyeri. Ubwo butumwa bwereka uko uri bwishyure. Numara kwisyura icyangombwa cyawe urahita ucyibona muri sisitemu."
    application_stage_issuance = "Icyangombwa cyabonetse"
    application_stage_issuance_text = "Hano icyangombwa cyawe kiba cyabonetse ushobora kujya muri sisitemu ukagifata."
    //!-- Application Stages

     // END - Main Page
}