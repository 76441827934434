import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError, throwError } from "rxjs";
import {environment} from "src/environments/environment"

@Injectable({
  providedIn: "root",
})
export class NewApplicationService {
  url = environment.backendUrl;
  token = localStorage.getItem("token");
  headers = new HttpHeaders().set("Authorization", `Bearer ${this.token}`);

  constructor(private _http: HttpClient) {}

  getUPIInfo(upi: string) {
    return this._http.get(this.url + "land?upi=" + upi).pipe(
      map((response: any) => {
        if (Object.keys(response.data).length === 0) {
          throw new Error("UPI not found");
        }
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }


  getRegulations(zoneName: string) {
    return this._http.get(this.url + "land/zones/" + zoneName + "/regulations").pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  getPermitTypes() {
    return this._http.get(this.url + "applications/permits/types").pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  getProjectCategories() {
    return this._http.get(this.url + "applications/projects/categories").pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  getBuildingTypes() {
    return this._http.get(this.url + "applications/buildings/types").pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  submitApplication(application: any) {
    return this._http
      .post(this.url + "applications", application, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          let errorMessage = "An unexpected error occurred.";
          if (error && error.error && error.error.message) {
            errorMessage = error.error.message;
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
