import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { userLoginCredentials,userSignupCredentials } from "../models/user";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  url =environment.backendUrl + "auth";
  constructor(
    private _http: HttpClient,
    private _jwtHelper: JwtHelperService
  ) {}

  signup(credentials: userSignupCredentials) {
    return this._http.post(this.url + "/signup", credentials).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        console.log("error", error);
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  login(credentials: userLoginCredentials) {
    return this._http.post(this.url + "/login", credentials).pipe(
      map((response: any) => {
        if (response) {
          localStorage.setItem("token", response.data["access_token"]);
          localStorage.setItem("role", response.data.role["name"]);
        }
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  forgotPassword(credentials: { email_or_phone: string }) {
    return this._http.post(this.url + "/forgot-password", credentials).pipe(
      map((response: any) => {
        if (response) {
          return response;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  resetPassword(credentials: { otp: number; password: string }) {
    return this._http.post(this.url + "/reset-password", credentials).pipe(
      map((response: any) => {
        if (response) {
          return response;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  }

  isLoggedIn() {
    const token = localStorage.getItem("token");
    if (!token) return false;

    const isExpired = this._jwtHelper.isTokenExpired(token);
    return !isExpired;
  }
}
