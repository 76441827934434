import { Injectable } from '@angular/core';
import { EnglishResource } from '../resources/english.resource';
import { FrenchResource } from '../resources/french.resource';
import { KinyarwandaResource } from '../resources/kinyarwanda.resource';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  EN = EnglishResource; // English
  FREN = FrenchResource // French
  KIN = KinyarwandaResource // Kinyarwanda
  constructor() { }
}