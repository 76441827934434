import { Component, OnInit } from "@angular/core";
import { EventTypes } from "src/app/models/toast";
import { ToasterService } from "src/app/services/toaster.service";
import { UsersService } from "src/app/services/users.service";
import { MatDialog } from "@angular/material/dialog";
import { AssignRolePopupComponent } from "../assign-role-popup/assign-role-popup.component";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  active = "users";
  users: any = [];
  searchQuery: string = "";
  searchResults: any = [];
  p: number = 1;
  agencies :any= []
  reviewerGroups:any = []

  constructor(
    private _toaster: ToasterService,
    private _usersService: UsersService,
    private _dialogRef: MatDialog
  ) {}

  onSearchInputChange(event: any) {
    this.searchQuery = event.target.value;
    if (this.searchQuery.length >= 3) {
      this.searchResults = this.users.filter((user: any) => {
        return (
          user.first_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          user.last_name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    } else {
      this.searchResults = this.users;
    }
  }

  openDialog(user: any,agencies:any,reviewerGroups:any) {
    this._dialogRef.open(AssignRolePopupComponent, {
      data: {
        user,
        agencies,
        reviewerGroups
      },
      width: "500px",
    });
  }

  ngOnInit(): void {
    this._usersService.getAllUsers().subscribe(
      (result: any) => {
        if (result && result.data) {
          this.users = result.data;
          this.searchResults = result.data;
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      } 
    );

    this._usersService.getAgencies().subscribe(
      (result: any) => {
        if (result && result.data) {
          this.agencies = result.data;
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {  
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );

    this._usersService.getReviewerGroups().subscribe(
      (result: any) => {
        if (result && result.data) {
          this.reviewerGroups = result.data;
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {  
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }
}
