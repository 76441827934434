import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private _messageService: MessageService) {}

  showToast(severity: string, summary: string, details: any, life?: number) {
    this._messageService.add({
      severity: severity,
      summary: summary,
      detail: details,
      life: life ? life : undefined,
    });
  }
}
