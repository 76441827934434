import { AuthenticationService } from "./../../services/authentication.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { userLoginCredentials } from "src/app/models/user";
import { MessageService } from "primeng/api";
import { ToasterService } from "src/app/services/toaster.service";
import { EventTypes } from "src/app/models/toast";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  users: any;
  loggingIn: boolean = false;

  constructor(
    private _toaster:ToasterService,
    private _router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {}
  signin(credentials: userLoginCredentials) {
    this.loggingIn = true;
    this.authService.login(credentials).subscribe(
      (result: any) => {
        this.loggingIn = false;
        if (result.data.role.name == "APPLICANT") {
          this._router.navigate(["/applications"]);
        } else {
          this._router.navigate(["/dashboard"]);
        }
      },
      (error: any) => {
        this.loggingIn = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }
}
