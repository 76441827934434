<p-toast></p-toast>
<div class="container p-5">
  <h4>User management</h4>
  <input
 type="text"
 class="form-control mb-3"
 placeholder="Search"
 [(ngModel)]="searchQuery"
 name="searchTerm"
 style="width: 25%"
 (input)="onSearchInputChange($event)"
/>
  <div class="table-responsive">

    <table
      class="table table-striped border rounded"
      style="vertical-align: middle"
    >
      <thead>
        <tr>
          <th scope="col" nowrap>Full name</th>
          <th scope="col" nowrap>Email</th>
          <th scope="col" nowrap>Role</th>
          <th scope="col" nowrap>Phone number</th>
          <th scope="col" nowrap>Assign role</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of searchResults | paginate: { itemsPerPage: 10, currentPage: p }">
          <td>{{ user?.first_name }} {{ user?.last_name }}</td>
          <td nowrap>{{ user?.email }}</td>
          <td>{{ user?.role?.name }}</td>
          <td>{{ user?.phone_number }}</td>
          <td>
              <button
                class="btn"
                type="button"
                style="margin-left: 2px"
                (click)="openDialog(user,agencies,reviewerGroups)"
              >
              <i class="bi bi-three-dots"></i>
              </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
    <pagination-controls (pageChange)="p = $event"  [responsive]="true"></pagination-controls>
</div>