<div class="text-center">
  <h4 class="mat-dialog-title">Assign Reviewers</h4>
  <hr />
  <div class="mat-dialog-content">
    <p style="font-size: large">
      assign reviewers to application with id:
      <span style="font-weight: 480; font-style: italic;">678-445636793-64734834</span>
    </p>

    <div class="d-flex">
      <div class="flex-grow-1 m-2">
        <input
          type="text"
          class="form-control mb-3"
          placeholder="Search"
          name="searchTerm"
          [(ngModel)]="searchQuery"
          (input)="onSearchInputChange($event)"
          style="width: 50%"
        />
        <div class="table-responsive">
          <table
            class="table table-striped border rounded"
            style="vertical-align: middle"
          >
            <thead>
              <tr>
                <th>Full name</th>
                <th>Email</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let reviewer of searchResults
                    | paginate : { itemsPerPage: 5, currentPage: p }
                "
              >
              <td>{{ reviewer?.first_name }} {{reviewer?.last_name}}</td>
              <td>{{ reviewer?.email }}</td>
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [(ngModel)]="reviewer.checked"
                  (change)="sortReviewers($event,reviewer)"
                  name=""
                  id=""
                />
              </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls
            (pageChange)="p = $event"
            [responsive]="true"
          ></pagination-controls>
        </div>
      </div>

      <div class="flex-grow-1 m-2 d-flex flex-column align-self-center">
        <h5>Selected reviewers</h5>
        <div class="select align-self-center m-2 p-2">
            <div *ngIf="selectedReviewers.length > 0; else default">
                <h6 *ngFor="let reviewer of selectedReviewers; let i = index">
                   {{i + 1}}. {{reviewer?.first_name}} {{reviewer?.last_name}}
                </h6>
            </div>
            <ng-template #default>
                <h6>No reviewers selected</h6>
                <p>select reviewers on the table</p>
            </ng-template>
        </div>
        <button class="btn btn-primary align-self-center m-2" style="min-width:40%">Assign reviewers</button>
      </div>
    </div>
  </div>
</div>
