import { PopupComponent } from './../popup/popup.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTypes } from 'src/app/models/toast';
import { ApplicationDetailsService } from 'src/app/services/application-details.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MatDialog } from '@angular/material/dialog';
import { AssignToReviewerComponent } from '../assign-to-reviewer/assign-to-reviewer.component';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip'
@Component({
  selector: "app-application-details",
  templateUrl: "./application-details.component.html",
  styleUrls: ["./application-details.component.scss"],
})
export class ApplicationDetailsComponent implements OnInit {
  applicationId: string = "";
  application: any = [];
  attachments: any = {};
  attachmentsArray: any[] = [];
  role: string | null;
  loadingApplication: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _applicationDetailsService: ApplicationDetailsService,
    private _toaster: ToasterService,
    private _dialogRef: MatDialog,
    private _http: HttpClient
  ) {
    this.role = localStorage?.getItem("role");
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((data: any) => {
      this.applicationId = data.params.id;
    });
    this._applicationDetailsService
      .getApplicationDetails(this.applicationId)
      .subscribe(
        (result: any) => {
          this.application = result.data;
          this.application.created_at = this.formatDate(result.data.created_at);
          console.log(result.data);
          this.attachments = result.data.attachments;
          this.attachmentsArray = Object.keys(result.data.attachments);
          this.attachmentsArray = this.attachmentsArray.filter((str:string)=>{
            return str !== "id" && str !== "created_at";
          })
          this.loadingApplication = false;
        },
        (error: any) => {
          this.loadingApplication = false;
          let errorMessage = "something went wrong, try again!";
          if (typeof error === "string") {
            errorMessage = error;
          } else if (error && error.message) {
            errorMessage = error.message;
          }

          this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
        }
      );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  openDialog(title: string, details: string) {
    this._dialogRef.open(PopupComponent, {
      data: {
        title,
        details,
      },
      width: "50%",
    });
  }

  openAssignReviewerPopUp() {
    this._dialogRef.open(AssignToReviewerComponent, {
      data: { user: 1 },
      width: "60%",
    });
  }

  downloadFile(url: string) {
    this._http.get(url, { responseType: "blob" }).subscribe((blob: Blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const fileName = this.getFileNameFromUrl(url);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  }

  getFileNameFromUrl(url: string): string {
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    const fileName = decodeURIComponent(lastPart);
    return fileName;
  }

  downloadFilesAsZip(attachmentsArray: string[], attachmentsObject: any, applicationId:string): void {
    const zip = new JSZip();
    const folder = zip.folder(`files_${applicationId}`) as JSZip;
    const downloadPromises :any= [];

    attachmentsArray.forEach((attachment) => {
      const url = attachmentsObject[attachment];
      if(url == '{}') return
      const fileName = this.getFileNameFromUrl(url); // Replace with desired file name pattern

      const downloadPromise = this._http
        .get(url, { responseType: "blob" })
        .toPromise()
        .then((response: any) => {
          const blob = new Blob([response], {
            type: "application/octet-stream",
          });
          folder.file(fileName, blob);
        });

      downloadPromises.push(downloadPromise);
    });

    Promise.all(downloadPromises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content: Blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = `${applicationId}.zip`;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    });
  }
}
