import { NgModule } from "@angular/core";
import { RouterModule, Routes, CanActivate } from "@angular/router";
import { ApplicationDetailsComponent } from "./components/application-details/application-details.component";
import { ApplicationsComponent } from "./components/applications/applications.component";
import { NewApplicationComponent } from "./components/new-application/new-application.component";
import { SearchComponent } from "./components/search/search.component";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { AuthGuard } from "./services/auth-guard.service";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { UserManagementComponent } from "./components/user-management/user-management.component";
import { HomeLayoutComponent } from "./home-layout/home-layout.component";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { AllApplicationsComponent } from "./components/all-applications/all-applications.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ReviewApplicationsComponent } from "./components/review-applications/review-applications.component";
import { TasksComponent } from "./components/tasks/tasks.component";
import { CheckEligibilityComponent } from "./components/check-eligibility/check-eligibility.component";

const routes: Routes = [
  {
    path: "",
    component: HomeLayoutComponent,
    children: [
      { path: "", component: SearchComponent },
      { path: "login", component: LoginComponent },
      { path: "signup", component: SignupComponent },
      { path: "forgot", component: ForgotPasswordComponent },
      { path: "reset", component: ResetPasswordComponent },
      { path: "eligibility", component: CheckEligibilityComponent },
      {
        path: "applications",
        component: ApplicationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "new-application",
        component: NewApplicationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "application-details/:id",
        component: ApplicationDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "dashboard",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: DashboardComponent,
      },
      {
        path: "user-management",
        component: UserManagementComponent,
      },
      {
        path: "applications",
        component: AllApplicationsComponent,
      },
      {
        path: "tasks",
        component: TasksComponent,
      },
      {
        path: "tasks/:id",
        component: ReviewApplicationsComponent,
      },
      {
        path: "applications-details/:id",
        component: ApplicationDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
