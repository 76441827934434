export class EnglishResource{
    // GENERAL
    general_login_text = "Login"
    // - GENERAL

    // Main Page
    main_page_title = "Building Permits";
    main_page_subtitle = "Track the status of your permit application here";
    main_upi_search_placeholder = "Search with UPI";
    main_search_button = "Search"
    main_spinner_text = "Contacting the permitting office..."

    main_current_status_text = "Current Status";

    //--Results story
    results_date_specifier = "On"
    results_permit_type = "you applied for the permit of ";
    results_permit_sector="in sector";
    results_application_number_text="with the application number"
    results_application_stage_text = "Your application is in stage"
    results_application_not_submitted_text = "You haven't submitted your application yet. Submit it today and start tracking its progress.";
    results_permit_approved_text="Congrats! Your application was was approved on"
    results_permit_number_text = "with the permit number"
    results_login_to_download = "to your account to download it.";
    //!--Results stage

    // -- Application Stages
    application_stage_tittle="Application stages"
    application_stage_submission = "Submission"
    application_stage_submission_text = "The initial stage when you submit your application"
    application_stage_review = "In Review"
    application_stage_review_text = "The application is being reviewed by permitting officers."
    application_stage_architect = "Back to Architect"
    application_stage_architect_text = "There was a problem with your application, and feedback was shared with the architect for adjustment."
    application_stage_invoicing = "Invoicing"
    application_stage_invoicing_text = "You have already received a message with instructions on how to pay the permit fees"
    application_stage_issuance = "Permit Issuance"
    application_stage_issuance_text = "Your permit has been issued and is ready for download. "

    //!-- Application Stages

     // END - Main Page
}