import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AttachmentsManagementService } from 'src/app/services/attachments-management.service';

@Component({
  selector: "app-review-applications",
  templateUrl: "./review-applications.component.html",
  styleUrls: ["./review-applications.component.scss"],
})
export class ReviewApplicationsComponent implements OnInit {
  uploads: any = {};
  currentTab = 'commentSheet'


  constructor(
    private _formBuilder: FormBuilder,
    private _attachmentsService: AttachmentsManagementService
  ) {}

  ngOnInit(): void {}
  form = this._formBuilder.group({
    developer_contacts_approved: this._formBuilder.control(""),
    developer_contacts_approved_comment: this._formBuilder.control(""),
    plot_location_provided: this._formBuilder.control(""),
    plot_location_provided_comment: this._formBuilder.control(""),
    plot_data_provided: this._formBuilder.control(""),
    plot_data_provided_comment: this._formBuilder.control(""),
    development_data_provided: this._formBuilder.control(""),
    development_data_provided_comment: this._formBuilder.control(""),
    land_ownership_data_provided: this._formBuilder.control(""),
    land_ownership_data_provided_comment: this._formBuilder.control(""),
    tax_payment_proof_attached: this._formBuilder.control(""),
    tax_payment_proof_attached_comment: this._formBuilder.control(""),
    location_maps_attached: this._formBuilder.control(""),
    location_maps_attached_comment: this._formBuilder.control(""),
    site_analysis_attached: this._formBuilder.control(""),
    site_analysis_attached_comment: this._formBuilder.control(""),
    design_report_attached: this._formBuilder.control(""),
    design_report_attached_comment: this._formBuilder.control(""),
    eia_report_attached: this._formBuilder.control(""),
    eia_report_attached_comment: this._formBuilder.control(""),
    site_plan_attached: this._formBuilder.control(""),
    site_plan_attached_comment: this._formBuilder.control(""),
    landscape_plan_attached: this._formBuilder.control(""),
    landscape_plan_attached_comment: this._formBuilder.control(""),
    geotechnical_plan_attached: this._formBuilder.control(""),
    geotechnical_plan_attached_comment: this._formBuilder.control(""),
    architectural_data_attached: this._formBuilder.control(""),
    architectural_data_attached_comment: this._formBuilder.control(""),
    structural_data_attached: this._formBuilder.control(""),
    structural_data_attached_comment: this._formBuilder.control(""),
    building_services_attached: this._formBuilder.control(""),
    building_services_attached_comment: this._formBuilder.control(""),
    safety_measures_plan_attached: this._formBuilder.control(""),
    safety_measures_plan_attached_comment: this._formBuilder.control(""),
    bill_of_quantities_attached: this._formBuilder.control(""),
    bill_of_quantities_attached_comment: this._formBuilder.control(""),
    certifications_attached: this._formBuilder.control(""),
    certifications_attached_comment: this._formBuilder.control(""),
    other_documents_attached: this._formBuilder.control(""),
    other_documents_attached_comment: this._formBuilder.control(""),
    site_visit_pictures: this._formBuilder.control(""),
    site_analysis: this._formBuilder.control(""),
    other_attachments: this._formBuilder.control(""),
    final_urban_planning_comments: this._formBuilder.control(""),
    final_architectural_comments: this._formBuilder.control(""),
    final_structural_comments: this._formBuilder.control(""),
    final_conditional_approval: this._formBuilder.control(""),
    final_decision: this._formBuilder.control(""),
    final_attach_letter: this._formBuilder.control(""),
    final_attachments: this._formBuilder.control(""),
    additional_upload_file: this._formBuilder.control(""),
    additional_comments: this._formBuilder.control(""),
  });

  addFile(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputFormControlName = inputElement.getAttribute("formControlName");

    if (inputElement.files && inputElement.files.length > 0) {
      this.form.patchValue({
        [inputFormControlName as string]: inputElement.files[0],
      });
    }
  }

  uploadFiles(form:any) {
    const keysOfFormFiles = [
      "site_visit_pictures",
      "site_analysis",
      "other_attachments",
      "final_attach_letter",
      "final_attachments",
      "additional_upload_file",
    ];
    const uploadPromises: Promise<any>[] = [];

    keysOfFormFiles.forEach((attachment) => {
      const file = this.form?.get(attachment)?.value;
      if (file instanceof File) {
        const uploadPromise = this._attachmentsService
          .uploadFile(file)
          .toPromise();
        uploadPromises.push(uploadPromise);
      }
    });

    if (uploadPromises.length > 0) {
      Promise.all(uploadPromises)
        .then((results: any[]) => {
          // Process the uploaded file results
          results.forEach((result, index) => {
            const file = keysOfFormFiles[index];
            if (result) {
              form.patchValue({
                [file]: result.data.url,
              })
            }
          });
          this.submitForm(form)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  submitForm(form:any) {
    console.log(form.value);
  }


  setTab(tabName:string) {
    this.currentTab = tabName
  }
}