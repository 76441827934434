import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, throwError } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class UsersService {
  url = environment.backendUrl;
  token = localStorage.getItem("token");
  headers = new HttpHeaders().set("Authorization", `Bearer ${this.token}`);
  constructor(private _http: HttpClient) {}

  getAllUsers() {
    return this._http.get(this.url + "users", { headers: this.headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  getUserPerRoles(role:string) {
    return this._http.get(this.url + `users?role=${role}`, { headers: this.headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  getAgencies() {
    return this._http
      .get(this.url + "agencies", { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          let errorMessage = "An unexpected error occurred.";
          if (error && error.error && error.error.message) {
            errorMessage = error.error.message;
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  getReviewerGroups() {
    return this._http
      .get(this.url + "agencies/reviewer_groups", { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          let errorMessage = "An unexpected error occurred.";
          if (error && error.error && error.error.message) {
            errorMessage = error.error.message;
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  assignRole(
    role: string,
    userId: string,
    agency_id?: string,
    group_id?: string
  ) {
    let body: any = {
      role_name: role,
    };

    if (role === "DIRECTOR") {
      body.agency_id = agency_id;
    }

    if (role === "REVIEWER") {
      body.group_id = group_id;
    }
    return this._http
      .put(`${this.url}users/${userId}/roles`, body, { headers: this.headers })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          let errorMessage = "An unexpected error occurred.";
          if (error && error.error && error.error.message) {
            errorMessage = error.error.message;
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
