import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  constructor() { }

  setLanguage(language: string){
    localStorage.setItem('lang', language )
  }

  getLanguage(){
    return localStorage.getItem('lang') || ""
  }
}
