<div class="s130">
  <form>
    <div class="text-center" style="font-size: 20pt;">
      {{locale.main_page_title}} <br>
    </div>
    <div class="text-center mb-4">
      <small class="text-muted">{{locale.main_page_subtitle}}</small>
    </div>
    <div class="inner-form">
      <div class="input-field first-wrap">
        <div class="svg-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </div>
        <input id="search" name="search" type="text" placeholder="{{locale.main_upi_search_placeholder}}" [(ngModel)]="upi" (keyup)="trimWhiteSpaces()" (keyup.enter)="search()" />
      </div>
      <div class="input-field second-wrap">
        <button class="btn-search" type="button" (click)="search()">{{locale.main_search_button | uppercase}}</button>
      </div>
    </div>
    <div class="d-flex" style="width:100%; justify-content: space-between;">
      <span class="info lang">
        <a href="?upi={{upi}}" (click)="setPreferedLanguage('eng')" *ngIf="language_preference!=='eng'">English</a> <span *ngIf="language_preference==='eng'" style="color: rgb(47, 133, 238);text-decoration:underline;">English</span>  | 
        <a href="?upi={{upi}}" (click)="setPreferedLanguage('fren')" *ngIf="language_preference!=='fren'">Français</a> <span *ngIf="language_preference==='fren'" style="color: rgb(47, 133, 238);text-decoration:underline;">Français</span> | 
        <a href="?upi={{upi}}" (click)="setPreferedLanguage('kinya')" *ngIf="language_preference!=='kinya'">Ikinyarwanda</a> <span *ngIf="language_preference==='kinya'" style="color: rgb(47, 133, 238);text-decoration:underline;">Ikinyarwanda</span>
      </span>
      <span>
        <a routerLink="eligibility" class="el" style="font-size: 15px;color:rgb(47, 133, 238);text-decoration: underline;">Check what's allowed on your land</a>
      </span>
    </div>

    <!-- Spinner -->
    <div class="spinner text-center mt-2" *ngIf="spinner_on">
      <span class="text-muted">{{locale.main_spinner_text}}</span> <br>
      <div class="spinner-border text-primary" role="status"></div>
      <!-- <div class="spinner-border text-secondary" role="status"></div>
      <div class="spinner-border text-success" role="status"></div>
      <div class="spinner-border text-danger" role="status"></div>
      <div class="spinner-border text-warning" role="status"></div>
      <div class="spinner-border text-info" role="status"></div> -->
    </div>
    <!-- Spinner -->

    <!-- Search Results -->
    <div class="alert alert-warning p-5" *ngIf="alert">{{alert}}</div>
      <div class="results text-center mt-5 text" *ngIf="searchResults.UPI2">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-4">
              <small class="text-muted">{{locale.main_current_status_text}}</small><br> 
              <span style="color: #005fad">{{searchResults.stage}}</span>
              <span style="color: #005fad" *ngIf="!searchResults.stage">Draft</span>
            </h4>
            <p style="font-size: 10pt;" *ngIf="searchResults.stage">{{locale.results_date_specifier}} <strong>{{searchResults.application_date | date}}</strong>, {{locale.results_permit_type}} <strong>"{{searchResults.permit_type | lowercase | titlecase}}"</strong> {{locale.results_permit_sector}} <strong>{{searchResults.sector}}</strong>, {{locale.results_application_number_text}} <strong>{{searchResults.application_number}}</strong>.
            <span *ngIf="!searchResults.permit_number">{{locale.results_application_stage_text}} <span style="color: #005fad">"{{searchResults.stage}}"</span></span></p>
            <span *ngIf="!searchResults.stage">{{locale.results_application_not_submitted_text}}</span>
            <div class="card" style="box-shadow: 0 8px 20px 0 rgb(33 50 255 / 31%); border-color: #84aed1; font-size: 10pt;" *ngIf="searchResults.permit_number">
              <div class="card-body">
                <i class="bi bi-file-earmark-check text-primary" style="font-size: 20pt;"></i>
                {{locale.results_permit_approved_text}} <strong>{{searchResults.permit_issue_date | date}}</strong> {{locale.results_permit_number_text}} <strong>{{searchResults.permit_number}}. </strong> <a href="https://bpmis.gov.rw/index.php/login" target="_blank" class="text-primary">{{locale.general_login_text}} </a> {{locale.results_login_to_download}}</div>
            </div>
          </div>
        </div>
      </div>
    <!-- Search Results -->

    <div class="col-12 mt-5" *ngIf="searchResults.stage" >
      <h3>{{locale.application_stage_tittle}}</h3>
      <!-- Timeline  -->
      <ul class="timeline">
        <li class="event" data-date="Stage 1">
            <h3 style="border: 3px solid #727cf5;padding: 14px;border-radius: 10px;" *ngIf="searchResults && (formatStage(searchResults.stage) === 'Res' || formatStage(searchResults.stage) === 'Sub')">{{locale.application_stage_submission}}</h3>
            <h3 *ngIf="searchResults && (formatStage(searchResults.stage) !== 'Res' && formatStage(searchResults.stage) !== 'Sub')">{{locale.application_stage_submission}}</h3>
            <small>{{locale.application_stage_submission_text}}</small>
        </li>
        <li class="event" data-date="Stage 2">
            <h3 style="border: 3px solid #727cf5;padding: 14px;border-radius: 10px;" *ngIf="searchResults && formatStage(searchResults.stage) === 'In-'">{{locale.application_stage_review}}</h3>
            <h3 *ngIf="searchResults && formatStage(searchResults.stage) !== 'In-'" >{{locale.application_stage_review}}</h3>
            <p>{{locale.application_stage_review_text}}</p>
        </li>
        <li class="event" data-date="Stage 3">
          <h3 style="border: 3px solid #727cf5;padding: 14px;border-radius: 10px;" *ngIf="searchResults && formatStage(searchResults.stage) === 'Bac'">{{locale.application_stage_architect}}</h3>
          <h3 *ngIf="searchResults && formatStage(searchResults.stage) !== 'Bac'" >{{locale.application_stage_architect}}</h3>
            <p>{{locale.application_stage_architect_text}}</p>
        </li>
        <li class="event" data-date="Stage 4">
          <h3 style="border: 3px solid #727cf5;padding: 14px;border-radius: 10px;" *ngIf="searchResults && formatStage(searchResults.stage) === 'Inv'">{{locale.application_stage_invoicing}}</h3>
          <h3 *ngIf="searchResults && formatStage(searchResults.stage) !== 'Inv'" >{{locale.application_stage_invoicing}}</h3>
          <p>{{locale.application_stage_invoicing_text}}</p>
      </li>
      <li class="event" data-date="Stage 5">
        <h3 style="border: 3px solid #727cf5;padding: 14px;border-radius: 10px;" *ngIf="searchResults && formatStage(searchResults.stage) === 'Per'"><i class="bi bi-file-earmark-check" style="color: #727cf5;"></i> {{locale.application_stage_issuance}}</h3>
          <h3 *ngIf="searchResults && formatStage(searchResults.stage) !== 'Per'" >{{locale.application_stage_issuance}}</h3>
        <p>{{locale.application_stage_issuance_text}}</p>
    </li>
    </ul>
      <!-- End Timeline -->
    </div>    
  </form>
</div>