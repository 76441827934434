 <p-toast></p-toast>
 <div class="container p-3" style="margin: auto;min-height: 80vh; display: flex; justify-content: center; align-items: center;">
    <div class="page-body" style="width:100%">
        <div class="card">
            <div class="card-header">
                <h4 class="text-center">Check eligibility</h4>
                <p class="text-center">
                  Track what is permitted, prohibited and conditional to be built in your plot
                </p>
            </div>
            <div class="card-body">
                <!-- <h4 class="text-center">A. Check eligibility</h4><br /> -->
                        <form action="" class="form-inline" [formGroup]="form">
                            <div [@fadeScale]>
                                       <div class="">
                                         <div class="row mb-2">
                                           <label class="col-sm-2 col-form-label">Plot No (UPI)</label>
                                           <div class="col-sm-7 mb-2">
                                             <input
                                               type="text"
                                               class="form-control"
                                               formControlName="plot_number"
                                               placeholder="x/xx/xx/xxxx"
                                             />
                                             <div *ngIf="UPI?.touched && !UPI?.valid">
                                               <p class="error" *ngIf="UPI?.errors?.['required']">UPI is required</p>
                                             </div>
                                           </div>
                                           <div class="col-sm-3">
                                             <button
                                               class="btn btn-primary"
                                               style="width: 100%"
                                               (click)="checkUPI(UPI?.value)"
                                               [disabled]="!(UPI?.valid)"
                                             >
                                               <span
                                                 class="spinner-border spinner-border-sm"
                                                 role="status"
                                                 aria-hidden="true"
                                                 *ngIf="checkingUpi; else default"
                                               ></span>
                                               <ng-template #default> Check UPI </ng-template>
                                             </button>
                                           </div>
                                         </div>
                                         <section class="inner-section" *ngIf="upiChecked">
                                           <div class="row">
                                             <div class="col">
                                               <strong>District:</strong
                                               >{{ checkUpiInfo.parcelLocation.district.districtName }}
                                               <br />
                                               <strong>Sector:</strong>
                                               {{ checkUpiInfo.parcelLocation.sector.sectorName }} <br />
                                               <strong>Cell:</strong>
                                               {{ checkUpiInfo.parcelLocation.cell.cellName }} <br />
                                               <strong>Village:</strong>
                                               {{ checkUpiInfo.parcelLocation.village.villageName }} <br />
                                               <p class="mt-2">
                                                 <strong>Registered use:</strong>
                                                 {{ checkUpiInfo.landUseNameEnglish }}
                                               </p>
                                             </div>
                                             <div class="col">
                                               <span
                                                 class="badge bg-secondary m-2"
                                                 style="font-size: x-large"
                                                 *ngFor="let plannedUse of checkUpiInfo?.plannedLandUses"
                                               >
                                                 <small style="font-size: x-small"
                                                   >Zoning Regulation:</small
                                                 ><br />
                                                 {{ plannedUse?.landUseName.slice(0,plannedUse?.landUseName.indexOf('-')) }}<br />
                                                 <small style="font-size: x-small"
                                                   >{{ plannedUse?.landUseName.slice(plannedUse?.landUseName.indexOf('-') + 1) }}</small
                                                 > </span
                                               ><br />
                                             </div>
                                           </div>
                                           <hr />
                                           <div class="alert alert-info text-center" *ngIf="checkUpiInfo?.plannedLandUses.length > 1">
                                           There is a road that passes through your plot.
                                           </div>
                                           <div class="row" *ngFor="let use of checkUpiInfo?.plannedLandUses">
                                             <h6>{{use?.landUseName}}</h6>
                                             <div class="col-sm-4">
                                               <strong>Permitted use</strong><br />
                                               <ul>
                                                 <li *ngFor="let condition of regulations[use?.landUseName]['permitted']">{{condition}} <i class="bi bi-check fs-5" style="color:green"></i></li>
                                               </ul>
                                             </div>
                                             <div class="col-sm-4">
                                               <strong>Prohibited use</strong><br />
                                               <ul>
                                                <li *ngFor="let condition of regulations[use?.landUseName]['prohibited']">{{condition}} <i class="bi bi-x fs-5" style="color:red"></i></li>
                                               </ul>
                                             </div>
                                             <div class="col-sm-4">
                                               <strong>Conditional use</strong><br />
                                               <ul>
                                                <li *ngFor="let condition of regulations[use?.landUseName]['conditional']">{{condition}}</li>
                                               </ul>
                                             </div>
                                           </div>
                                         </section>
                                         <div>
                                         </div>
                                       </div>                             </div>
</form>
            </div>
        </div>
    </div>
 </div>