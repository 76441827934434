import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EventTypes } from "src/app/models/toast";
import { ToasterService } from "src/app/services/toaster.service";
import { UsersService } from "src/app/services/users.service";

@Component({
  selector: "app-assign-role-popup",
  templateUrl: "./assign-role-popup.component.html",
  styleUrls: ["./assign-role-popup.component.scss"],
})
export class AssignRolePopupComponent implements OnInit {
  user: any;
  role: string = "REVIEWER";
  agency:string = ""
  reviewerGroup:string = ""
  agencies:any = {}
  reviewerGroups:any = []
  assigningRole: boolean = false;
  assignedRole: boolean = false;

  constructor(
    private _usersService: UsersService,
    private _toaster: ToasterService,
    private _dialogRef: MatDialogRef<AssignRolePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = data.user;
    this.reviewerGroups = data.reviewerGroups
    this.agencies = data.agencies
    this.agency = data.agencies[0]?.id
    this.reviewerGroup = data.reviewerGroups[0]?.id;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.assignedRole = false;
    this._dialogRef.close();
  }

  setRole(event: any) {
    this.role = event?.target?.value;
  }

  setAgency(event: any) {
    this.agency = event?.target?.value;
  }
  setReviewerGroup(event: any) {
    this.reviewerGroup = event?.target?.value;
  }

  assignRole(role: string, userId: string, agency_id = this.agency, group_id = this.reviewerGroup) {
    this.assigningRole = true;
    console.log('agency', this.agency)
    this._usersService.assignRole(role, userId, agency_id, group_id).subscribe(
      (result: any) => {
        if (result) {
          this.assignedRole = true;
        }
      },
      (error: any) => {
        this.assigningRole = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }
}
