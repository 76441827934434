<p-toast></p-toast>
<div class="container" style="margin: auto">
  <div class="">
    <h1 class="">New Application</h1>
  </div>
  <div class="page-body">
    <div class="card">
      <div class="card-body">
        <form action="" class="form-inline" [formGroup]="form">
          <div class="step-container" *ngIf="step === 1" [@fadeScale]>
            <div class="card-header">
              <ul>
                <li>
                  All fields marked with (*) are required for your application
                  to be processed and permitted
                </li>
                <li>
                  Your application will be lodged in the district where your
                  plot is located
                </li>
                <li>
                  If you have more files to upload than the available fields,
                  kindly put them in one folder, zip, and upload the folder.
                </li>
                <li>
                  All building categories (except categories 2 & 3) will be
                  required to have an Environmental Impact Assessment (EIA)
                  Certificate
                </li>
              </ul>
            </div>
            <div class="project-section">
              <h4>A. Plot Details</h4>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Plot No (UPI)</label>
                <div class="col-sm-7 mb-2">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="plot_number"
                    placeholder="x/xx/xx/xxxx"
                  />
                  <div *ngIf="UPI?.touched && !UPI?.valid">
                    <p class="error" *ngIf="UPI?.errors?.['required']">UPI is required</p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <button
                    class="btn btn-primary"
                    style="width: 100%"
                    (click)="checkUPI(UPI?.value)"
                    [disabled]="!(UPI?.valid)"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="checkingUpi; else default"
                    ></span>
                    <ng-template #default> Check UPI </ng-template>
                  </button>
                </div>
              </div>
              <section class="inner-section" *ngIf="upiChecked">
                <div class="row">
                  <div class="col">
                    <strong>District:</strong
                    >{{ checkUpiInfo.parcelLocation.district.districtName }}
                    <br />
                    <strong>Sector:</strong>
                    {{ checkUpiInfo.parcelLocation.sector.sectorName }} <br />
                    <strong>Cell:</strong>
                    {{ checkUpiInfo.parcelLocation.cell.cellName }} <br />
                    <strong>Village:</strong>
                    {{ checkUpiInfo.parcelLocation.village.villageName }} <br />
                    <p class="mt-2">
                      <strong>Registered use:</strong>
                      {{ checkUpiInfo.landUseNameEnglish }}
                    </p>
                  </div>
                  <div class="col">
                    <span
                      class="badge bg-secondary m-2"
                      style="font-size: x-large"
                      *ngFor="let plannedUse of checkUpiInfo?.plannedLandUses"
                    >
                      <small style="font-size: x-small"
                        >Zoning Regulation:</small
                      ><br />
                      {{ plannedUse?.landUseName.slice(0,plannedUse?.landUseName.indexOf('-')) }}<br />
                      <small style="font-size: x-small"
                        >{{ plannedUse?.landUseName.slice(plannedUse?.landUseName.indexOf('-') + 1) }}</small
                      > </span
                    ><br />
                  </div>
                </div>
                <hr />
                <div class="row" *ngFor="let use of checkUpiInfo?.plannedLandUses">
                  <h6>{{use?.landUseName}}</h6>
                  <div class="col-sm-4">
                    <strong>Permitted use</strong><br />
                    <ul>
                      <li *ngFor="let condition of regulations[use?.landUseName]['permitted']">{{condition}}</li>
                    </ul>
                  </div>
                  <div class="col-sm-4">
                    <strong>Prohibited use</strong><br />
                    <ul>
                     <li *ngFor="let condition of regulations[use?.landUseName]['prohibited']">{{condition}}</li>
                    </ul>
                  </div>
                  <div class="col-sm-4">
                    <strong>Conditional use</strong><br />
                    <ul>
                     <li *ngFor="let condition of regulations[use?.landUseName]['conditional']">{{condition}}</li>
                    </ul>
                  </div>
                </div>
              </section>
              <div>
                <button class="btn btn-primary m-2" (click)="nextStep()" [disabled]="!upiChecked">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 2" [@fadeScale]>
            <div class="project-section" formGroupName="project_details">
              <h4>B. Project Details</h4>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">Permit Type</label>
                  <div>
                    <select
                      formControlName="permit_type_id"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option  *ngFor="let permit of permitTypes" [value]="permit?.id">{{permit?.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Project Category</label>
                  <div>
                    <select
                      formControlName="category_id"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option *ngFor="let category of projectCategories" [value]="category?.id">{{category.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">Building Type</label>
                  <div>
                    <select
                      formControlName="building_type_id"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option *ngFor="let type of buildingTypes" [value]="type?.id">{{type.name}}</option>
                    </select>
                    <!-- <div class="alert alert-success mt-2">The selected building type is <strong>permitted</strong> under R4 zoning regulation of this plot. </div> -->
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Project Name</label>
                  <div>
                    <input
                      formControlName="project_name"
                      type="text"
                      class="form-control"
                      placeholder="x/xx/xx/xxxx"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <label class="col-form-label">Project Brief</label>
                <div class="">
                  <textarea
                    formControlName="project_description"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                    placeholder="Enter a brief project description here..."
                  ></textarea>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 3" [@fadeScale]>
            <div class="project-section" formGroupName="developer_details">
              <h4>C. Developer Details</h4>
              <div class="row">
                <div class="col-md-12">
                  <section class="inner-section mb-2" *ngIf="upiChecked">
                    <strong class="d-inline">Names: </strong>{{developerInfo?.surname}} {{developerInfo?.foreNames}} <br />
                    <strong>National ID Number / Passport Number:</strong>
                    {{developerInfo?.idNo}} <br />
                  </section>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">Phone Number</label>
                  <div>
                    <input
                      formControlName="phone_number"
                      type="text"
                      class="form-control"
                      placeholder="+250781664001"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Email</label>
                  <div>
                    <input
                      formControlName="email"
                      type="text"
                      class="form-control"
                      placeholder="eg: test@email.com"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">Tax Number (TIN)</label>
                  <div>
                    <input
                      formControlName="tin_number"
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="11111111111"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Address Street Number</label>
                  <div>
                    <input
                      formControlName="address_street"
                      type="text"
                      class="form-control"
                      placeholder="Street Number"
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">Address Line 2</label>
                  <div>
                    <input
                      formControlName="address_line_2"
                      type="text"
                      class="form-control"
                      placeholder="Address Line 2"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Country</label>
                  <div class="mb-2">
                    <select
                      formControlName="address_country"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option *ngFor="let country of countriesAndCodes" [selected]="country.name === 'Rwanda'">{{country?.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-6">
                  <label class="col-form-label">City</label>
                  <input
                    formControlName="address_city"
                    type="text"
                    class="form-control"
                    placeholder="City"
                  />
                </div>
                <div class="col-sm-6">
                  <label class="col-form-label">Other project owners</label><small>(Comma separated)</small>
                  <div>
                    <input
                      formControlName="other_project_owners"
                      type="text"
                      class="form-control"
                      placeholder="Jane Doe, Marina Bay, Alexa Roy"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 4" [@fadeScale]>
            <div class="project-section" formGroupName="development_details">
              <h4>D. Development Details</h4>
              <div class="row mb-2">
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Plot Size (in square meters) *</label
                  >
                  <input
                    formControlName="plot_size"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                   <div>
                    <p class="error" *ngIf="developmentDetails?.get('plot_size')?.errors?.['required'] && developmentDetails?.get('plot_size')?.touched">Plot size is required</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Built-up Area (in square meters) *</label
                  >
                  <input
                    formControlName="built_up_area"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                   <div>
                    <p class="error" *ngIf="developmentDetails?.get('built_up_area')?.errors?.['required'] && developmentDetails?.get('built_up_area')?.touched">Built up area is required</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label">Building Coverage: </label
                  ><br />
                  <strong style="font-size: x-large">  {{
                  (developmentDetails?.get('built_up_area')?.value && developmentDetails?.get('plot_size')?.value) ? (+developmentDetails?.get('built_up_area')?.value! / +developmentDetails?.get('plot_size')?.value!) * 100 : 0
                
                }}%</strong>
                </div>
                <div class="row mb-2 mt-3">
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >Proposed number of floors *</label
                    >
                    <input
                      formControlName="floors_count"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                     <div>
                    <p class="error" *ngIf="developmentDetails?.get('floors_count')?.errors?.['required'] && developmentDetails?.get('floors_count')?.touched">Proposed number of floors is required</p>
                  </div>
                  </div>
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >Gross Floor Area (in square meters) *</label
                    >
                    <input
                      formControlName="gross_floor_area"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                      <div>
                    <p class="error" *ngIf="developmentDetails?.get('gross_floor_area')?.errors?.['required'] && developmentDetails?.get('gross_floor_area')?.touched">Gross floor area is required</p>
                  </div>
                  </div>
                  
                  <div class="col-md-4">
                    <label class="col-form-label"> Gross Floor Area Ratio</label
                    ><br />
                     <strong style="font-size: x-large">  {{
                  (developmentDetails?.get('gross_floor_area')?.value && developmentDetails?.get('plot_size')?.value) ? (+developmentDetails?.get('gross_floor_area')?.value! / +developmentDetails?.get('plot_size')?.value!) : 0
                
                }}X</strong>
                  </div>
                </div>
                <div class="row mb-2 mt-3">
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >Number of Parking Spaces *</label
                    >
                    <input
                      formControlName="parking_spaces_count"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                    <div>
                    <p class="error" *ngIf="developmentDetails?.get('parking_spaces_count')?.errors?.['required'] && developmentDetails?.get('parking_spaces_count')?.touched">Number of parking spaces is required</p>
                  </div>
                  </div>
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >If Mixed Use, estimated % of space designated to each
                      use</label
                    >
                    <input
                      formControlName="space_designated_per_use_percent"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >Number of Dwelling Units (For Multifamily
                      Residential)</label
                    ><br />
                    <input
                      formControlName="dwelling_units_count"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                  </div>
                </div>
                <div class="row mb-2 mt-3">
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >Price Of Dwelling Unit in RWF (For Affordable
                      Housing)</label
                    >
                    <input
                      formControlName="dwelling_unit_price"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-form-label"
                      ><strong>Capacity Information:</strong> For hotel no of
                      rooms, for restaurants no of seats, for hospital no of
                      beds, for school no of students, for auditorium/conference
                      room no of people/seats</label
                    >
                    <input
                      formControlName="capacity_information"
                      type="number"
                      class="form-control"
                      min="1"
                    />
                  </div>
                  <div class="col-md-4">
                    <label class="col-form-label"
                      >For industrial project - description of operations</label
                    ><br />
                    <textarea
                      formControlName="industrial_operations_description"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                      style="height: 96px"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()" [disabled]="!developmentDetails?.valid">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 5" [@fadeScale]>
            <div class="project-section" formGroupName="project_estimates">
              <h4>E. Project Estimates</h4>
              <div class="row mb-2 mt-3">
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Estimated monthly water consumption in cubic meters
                    (m<sup>3</sup>) *</label
                  >
                  <input
                    formControlName="monthly_water_consumption"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                   <div>
                    <p class="error" *ngIf="projectEstimates?.get('monthly_water_consumption')?.errors?.['required'] && projectEstimates?.get('monthly_water_consumption')?.touched">Estimated monthly water consumption is required</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Estimated monthly electricty consumption in KwH *</label
                  >
                  <input
                    formControlName="monthly_electricity_consumption"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                   <div>
                    <p class="error" *ngIf="projectEstimates?.get('monthly_electricity_consumption')?.errors?.['required'] && projectEstimates?.get('monthly_electricity_consumption')?.touched">Estimated monthly electricity consumption is required</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <label class="col-form-label"
                    >Estimated distance to the nearest land line / fiber optic
                    cable (m)</label
                  >
                  <input
                    formControlName="distance_to_fiber_or_landline"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                </div>
              </div>
              <div class="row mb-2 mt-3">
                <div class="col-md-6">
                  <label class="col-form-label"
                    >Estimated project cost Rwf</label
                  >
                  <input
                    formControlName="project_cost_rwf"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                </div>
                <div class="col-md-6">
                  <label class="col-form-label"
                    >Estimated project cost USD</label
                  >
                  <input
                    formControlName="project_cost_usd"
                    type="number"
                    class="form-control"
                    min="1"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 mb-2 mt-3">
                  <div class="col-md-12">
                    <label class="col-form-label"
                      >Technology Survey will be carried out for:</label
                    >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (change)="trackCheckboxes($event)"
                        value="ADSL"
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault"
                        >ADSL</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (change)="trackCheckboxes($event)"
                        value="EVDO"
                        id="EVDO"
                      />
                      <label class="form-check-label" for="EVDO">EVDO</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        (change)="trackCheckboxes($event)"
                        value="land line"
                        id="landLine"
                      />
                      <label class="form-check-label" for="landLine"
                        >Land line</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="WBB"
                        (change)="trackCheckboxes($event)"
                        id="WBB"
                      />
                      <label class="form-check-label" for="WBB">WBB</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="webhosting"
                        (change)="trackCheckboxes($event)"
                        id="webHosting"
                      />
                      <label class="form-check-label" for="webHosting"
                        >Webhosting</label
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="Wimax"
                         (change)="trackCheckboxes($event)"
                        id="wimax"
                      />
                      <label class="form-check-label" for="wimax">Wimax</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mb-2 mt-3">
                  <div class="col-md-12">
                    <label class="col-form-label">Any other comments?</label>
                    <textarea
                      formControlName="project_estimates_comments"
                      class="form-control"
                      id=""
                      cols="30"
                      rows="5"
                      style="height: 96px"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()" [disabled]="!projectEstimates?.valid">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 6" [@fadeScale]>
            <div class="project-section" formGroupName="eia_details">
              <h4>
                F. Environmental Impact Assessment (For complex projects in
                category 4, 5 and 6)
              </h4>
              <h6>1) Waste production & management</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Construction Phase</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="waste_production_mgt_construction_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Operational Phase</label>
                <div class="col-sm-10">
                  <textarea
                  formControlName="waste_production_mgt_operation_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <h6>2) Likely impact and mitigation measure</h6>
              <h6>A) Positive Impacts</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Construction Phase</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="positive_impact_construction_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Operational Phase</label>
                <div class="col-sm-10">
                  <textarea
                  formControlName="positive_impact_operation_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <h6>B) Negative Impacts</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Construction Phase</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="negative_impact_construction_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Operational Phase</label>
                <div class="col-sm-10">
                  <textarea
                  formControlName="negative_impact_operation_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>

              <h6>C) Project Activites</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Construction Phase</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="project_activities_construction_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Operational Phase</label>
                <div class="col-sm-10">
                  <textarea
                  formControlName="project_activities_operation_phase"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>

              <h6>D) Biological and Social Economi Description</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Biological Environment</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="biological_environment"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Physical Environment</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="physical_environment"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Social Economic Environment</label
                >
                <div class="col-sm-10">
                  <textarea
                  formControlName="socio_economic_environment"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>

              <h6>3) Proposed EIA terms of reference</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Details</label>
                <div class="col-sm-10">
                  <textarea
                  formControlName="eia_terms_of_reference"
                    class="form-control"
                    cols="30"
                    rows="10"
                    style="height: 96px"
                  ></textarea>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 7" [@fadeScale]>
            <div class="project-section" formGroupName="attachments">
              <h4>G. Attachments</h4>
              <p class="text-info">Please uplaod PDF or ZIP attachments only</p>
              <h6>Proof of payments</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Receipt of property tax payment for the current year *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="property_tax_receipt" (change)="addFile($event)" />
                   <div>
                    <p class="error" *ngIf="attachments?.get('property_tax_receipt')?.errors?.['required'] && attachments?.get('property_tax_receipt')?.touched">Receipt of property tax payment is required</p>
                  </div>
                </div>
              </div>

              <h6>Location maps</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Plot Location Map *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="plot_location_map_1" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('plot_location_map_1')?.errors?.['required'] && attachments?.get('plot_location_map_1')?.touched">Plot location map is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Plot Location Map 2 *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="plot_location_map_2" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('plot_location_map_2')?.errors?.['required'] && attachments?.get('plot_location_map_2')?.touched">Plot location map 2 is required</p>
                  </div>
                </div>
              </div>

              <h6>Site analysis</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Site analysis *</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="site_analysis" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('site_analysis')?.errors?.['required'] && attachments?.get('site_analysis')?.touched">Site analysis document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Site analysis at the plot scale (approx. scale 1:5.000
                  -1:5.000 or 1:2.000 depending on plot size)</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="site_analysis_plot_scale" (change)="addFile($event)"/>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Design Report *</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="design_report" (change)="addFile($event)"/>
                  <div>
                    <p class="error" *ngIf="attachments?.get('design_report')?.errors?.['required'] && attachments?.get('design_report')?.touched">Design report is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">
                  Environmental Impact Assessment Report or Clearance *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="eia_report" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('eia_report')?.errors?.['required'] && attachments?.get('eia_report')?.touched">EIA report is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Site plan *</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="site_plan" (change)="addFile($event)"/>
                <div>
                    <p class="error" *ngIf="attachments?.get('site_plan')?.errors?.['required'] && attachments?.get('site_plan')?.touched">Site plan is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Geotechnical data *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="geo_technical_data" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('geo_technical_data')?.errors?.['required'] && attachments?.get('geo_technical_data')?.touched">Geotechnical data document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Architectural Data *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="architectural_data" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('architectural_data')?.errors?.['required'] && attachments?.get('architectural_data')?.touched">Architectural data document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Structural Data *</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="structural_data" (change)="addFile($event)"/>
                  <div>
                    <p class="error" *ngIf="attachments?.get('structural_data')?.errors?.['required'] && attachments?.get('structural_data')?.touched">Structural data document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Copy of green building minimum compliance report *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="green_building_min_compliance_report" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('green_building_min_compliance_report')?.errors?.['required'] && attachments?.get('green_building_min_compliance_report')?.touched">green building minimum compliance report is required</p>
                  </div>
                </div>
              </div>

              <h6>Building services</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Plumbing, Drainage, Storm water and Waste water data *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="plumbing_drainage_water_plan" (change)="addFile($event)"/>
                    <div>
                    <p class="error" *ngIf="attachments?.get('plumbing_drainage_water_plan')?.errors?.['required'] && attachments?.get('plumbing_drainage_water_plan')?.touched">Plumbing, Drainage, Storm water and Waste water data is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Electrical, Telecommunication and Mechanical data *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="electrical_telecom_mechanical_data" (change)="addFile($event)"/>
                  <div>
                    <p class="error" *ngIf="attachments?.get('electrical_telecom_mechanical_data')?.errors?.['required'] && attachments?.get('electrical_telecom_mechanical_data')?.touched">Electrical, Telecommunication and Mechanical data is required</p>
                  </div>
                </div>
              </div>

              <h6>Safety measure plan</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Fire Protection *</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="fire_protection" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('fire_protection')?.errors?.['required'] && attachments?.get('fire_protection')?.touched">Fire Protection document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Building security *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="building_security" (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('building_security')?.errors?.['required'] && attachments?.get('building_security')?.touched">Building security document is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Bill of Quantities</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="bill_of_quantities" (change)="addFile($event)"/>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Certifications . Please print Self Certification, sign, scan
                  and upload here. (refer to Chapter 5 of Checklist) *</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="certifications"  (change)="addFile($event)"/>
                   <div>
                    <p class="error" *ngIf="attachments?.get('certifications')?.errors?.['required'] && attachments?.get('certifications')?.touched">Certification is required</p>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label"
                  >Any previous communication (Letters)</label
                >
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="prev_communication" (change)="addFile($event)"/>
                </div>
              </div>

              <h6>Additional documents</h6>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Additional upload 1</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="additional_upload_1" (change)="addFile($event)"/>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Additional upload 2</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="additional_upload_2" (change)="addFile($event)"/>
                </div>
              </div>
              <div class="row mb-2">
                <label class="col-sm-2 col-form-label">Additional upload 3</label>
                <div class="col-sm-10">
                  <input type="file" class="form-control" formControlName="additional_upload_3"  (change)="addFile($event)"/>
                </div>
              </div>
              <div>
                <button class="btn btn-primary m-2" (click)="previousStep()">
                  Back
                </button>
                <button class="btn btn-primary m-2" (click)="nextStep()" [disabled]="!attachments?.valid">
                  Next
                </button>
              </div>
            </div>
          </div>

          <div class="step-container" *ngIf="step === 8" [@fadeScale]>
            <div class="row">
              <h4>H. Submit</h4>
              <div class="col">
                <button class="btn btn-primary" style="width: 100%" (click)="previousStep()">
                  Previous
                </button>
              </div>
              <div class="col">
                <button class="btn btn-primary" style="width: 100%" (click)="uploadAndSubmit(form)">
                   <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submittingForm; else default"
            ></span>
            <ng-template #default> Submit </ng-template>
                </button>
              </div>
              <div class="col">
                <button class="btn btn-secondary" style="width: 100%">
                  Save Draft
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="step-container" *ngIf="step === 9" style="text-align: center;" [@fadeScale]>
    <h3>You have successfully submitted your application</h3>
    <i class="bi bi-check-circle " style="font-size: 4rem;color:rgb(46, 156, 46)"></i><br>
    <a class="btn btn-primary" routerLink="/applications">
      view applications
    </a>
        </div>
      </div>
    </div>
  </div>
</div>  
