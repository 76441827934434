import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EventTypes } from 'src/app/models/toast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  submitting:boolean = false;
  constructor(public authService:AuthenticationService,private _messageService:MessageService, private _router:Router, private _toaster: ToasterService) {}

  ngOnInit(): void {}

  showResponse(severity:string,summary:string,details: any) {
    this._messageService.add({
      severity: severity,
      summary: summary,
      detail: details,
      life:7000
    });
  }

  forgotPassword(credentials : {email_or_phone : string}) {
    this.submitting = true;
    this.authService.forgotPassword(credentials).subscribe(
      (result: any) => {
        if (result) {
          this.submitting = false;
          console.log(result)
          this._toaster.showToast(EventTypes.Success,'Success', 'Check your email/phone provided for a code to reset your password, redirecting to reset password page in few seconds', 7000);

        } 
          setTimeout(() => {
            this._router.navigate(["/reset"]);
          }, 7000);
      },
      (error: any) => {
        this.submitting = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }
}
