import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

interface data {
  title: string;
  details: string;
}

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit {
  title: string;
  details: string;
  constructor(
    private _dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: data
  ) {
    this.title = data.title;
    this.details = data.details;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this._dialogRef.close();
  }
}
