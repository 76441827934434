import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  dashboard: boolean = false;
  role: string | null='';

  constructor(
    public AuthService: AuthenticationService,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.role = localStorage.getItem("role");
  }

  signout() {
    this.AuthService.logout();
    this._router.navigate(["/"]);
  }
}
