import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { NewApplicationService } from "src/app/services/new-application.service";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { EventTypes } from "src/app/models/toast";
import { ToasterService } from "src/app/services/toaster.service";
import { predefinedTypes } from "src/app/models/new-application";
import { AttachmentsManagementService } from "src/app/services/attachments-management.service";
import { CountriesService } from "src/app/services/countries.service";
import { Observable, forkJoin } from "rxjs";

@Component({
  selector: "app-new-application",
  templateUrl: "./new-application.component.html",
  styleUrls: ["./new-application.component.scss"],
  animations: [
    trigger("fadeScale", [
      transition(":enter", [
        style({ opacity: 0, transform: "scale(0.5)" }),
        animate(
          "0.5s ease-in-out",
          style({ opacity: 1, transform: "scale(1)" })
        ),
      ]),
      transition(":leave", [
        animate(
          "0.5s ease-in-out",
          style({ opacity: 0, transform: "scale(0.5)" })
        ),
      ]),
    ]),
  ],
})
export class NewApplicationComponent implements OnInit {
  step: number = 1;
  upiChecked = false;
  checkingUpi = false;
  checkUpiInfo: any;
  permitTypes: predefinedTypes[] = [];
  projectCategories: predefinedTypes[] = [];
  buildingTypes: predefinedTypes[] = [];
  developerInfo: any = "";
  buildingCoverage: number = 0;
  submittingForm = false;
  isSubmitted = false;
  countriesAndCodes: {
    name: string;
    code: string;
    dial_code: string;
  }[] = [];
  regulations: any = {};

  constructor(
    private _formBuilder: FormBuilder,
    private _newApplicationService: NewApplicationService,
    private _attachmentsService: AttachmentsManagementService,
    private _countriesCodesService: CountriesService,
    private _toaster: ToasterService,
    private _renderer: Renderer2
  ) {}

  nextStep() {
    if (this.step == 1) {
      //look for building permit types  in local storage or fetch them
      const savedTypes = JSON.parse(
        localStorage.getItem("permitTypes") as string
      );
      if (savedTypes && savedTypes.length != 0) {
        this.permitTypes = savedTypes;
      } else {
        this.getPermitTypes();
      }

      //look for project categories in local storage or fetch them

      const savedCategories = JSON.parse(
        localStorage.getItem("projectCategories") as string
      );
      if (savedCategories && savedCategories.length != 0) {
        this.projectCategories = savedCategories;
      } else {
        this.getProjectCategories();
      }

      //look for building types in local storage or fetch them

      const savedBuildingTypes = JSON.parse(
        localStorage.getItem("buildingTypes") as string
      );
      if (savedBuildingTypes && savedBuildingTypes.length != 0) {
        this.buildingTypes = savedBuildingTypes;
      } else {
        this.getBuildingTypes();
      }
    }

    if (this.step == 2 && this.countriesAndCodes.length == 0) {
      this.getCountriesAndCodes();
      console.log(this.countriesAndCodes);
    }

    this.step += 1;
    this.scrollToTop();
  }

  previousStep() {
    this.step -= 1;
    this.scrollToTop();
  }

  scrollToTop() {
    const config: ScrollToOptions = {
      top: 0,
      left: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling
    };

    this._renderer.setProperty(document.documentElement, "scrollTop", 0);
    this._renderer.setProperty(document.body, "scrollTop", 0);
    window.scrollTo(config);
  }

  form = this._formBuilder.group({
    plot_number: this._formBuilder.control("", [
      Validators.required,
      Validators.minLength(12),
    ]),
    project_details: this._formBuilder.group({
      permit_type_id: this._formBuilder.control(""),
      category_id: this._formBuilder.control(""),
      building_type_id: this._formBuilder.control(""),
      project_name: this._formBuilder.control(""),
      project_description: this._formBuilder.control(""),
    }),
    developer_details: this._formBuilder.group({
      first_name: this._formBuilder.control(""), //to be changer
      last_name: this._formBuilder.control(""),
      phone_number: this._formBuilder.control(""),
      email: this._formBuilder.control(""),
      tin_number: this._formBuilder.control(""),
      address_street: this._formBuilder.control(""),
      address_line_2: this._formBuilder.control(""),
      address_country: this._formBuilder.control(""),
      address_city: this._formBuilder.control(""),
      other_project_owners: this._formBuilder.control(""),
    }),
    district_id: this._formBuilder.control(""),
    sector_id: this._formBuilder.control(""),
    development_details: this._formBuilder.group({
      plot_size: this._formBuilder.control("", Validators.required),
      built_up_area: this._formBuilder.control("", Validators.required),
      floors_count: this._formBuilder.control("", Validators.required),
      gross_floor_area: this._formBuilder.control("", Validators.required),
      parking_spaces_count: this._formBuilder.control("", Validators.required),
      space_designated_per_use_percent: this._formBuilder.control(""),
      dwelling_units_count: this._formBuilder.control(""),
      dwelling_unit_price: this._formBuilder.control(""),
      capacity_information: this._formBuilder.control(""),
      industrial_operations_description: this._formBuilder.control(""),
    }),
    project_estimates: this._formBuilder.group({
      monthly_water_consumption: this._formBuilder.control(
        "",
        Validators.required
      ),
      monthly_electricity_consumption: this._formBuilder.control(
        "",
        Validators.required
      ),
      distance_to_fiber_or_landline: this._formBuilder.control(""),
      project_cost_rwf: this._formBuilder.control(""),
      project_cost_usd: this._formBuilder.control(""),
      tech_survey_details: this._formBuilder.array([]),
      project_estimates_comments: this._formBuilder.control(""),
    }),
    eia_details: this._formBuilder.group({
      waste_production_mgt_construction_phase: this._formBuilder.control(""),
      waste_production_mgt_operation_phase: this._formBuilder.control(""),
      positive_impact_construction_phase: this._formBuilder.control(""),
      positive_impact_operation_phase: this._formBuilder.control(""),
      negative_impact_construction_phase: this._formBuilder.control(""),
      negative_impact_operation_phase: this._formBuilder.control(""),
      project_activities_construction_phase: this._formBuilder.control(""),
      project_activities_operation_phase: this._formBuilder.control(""),
      biological_environment: this._formBuilder.control(""),
      physical_environment: this._formBuilder.control(""),
      socio_economic_environment: this._formBuilder.control(""),
      eia_terms_of_reference: this._formBuilder.control(""),
    }),
    attachments: this._formBuilder.group({
      property_tax_receipt: this._formBuilder.control("", Validators.required),
      plot_location_map_1: this._formBuilder.control("", Validators.required),
      plot_location_map_2: this._formBuilder.control("", Validators.required),
      site_analysis: this._formBuilder.control("", Validators.required),
      site_analysis_plot_scale: this._formBuilder.control(""),
      design_report: this._formBuilder.control("", Validators.required),
      eia_report: this._formBuilder.control("", Validators.required),
      site_plan: this._formBuilder.control("", Validators.required),
      structural_data: this._formBuilder.control("", Validators.required),
      geo_technical_data: this._formBuilder.control("", Validators.required),
      architectural_data: this._formBuilder.control("", Validators.required),
      green_building_min_compliance_report: this._formBuilder.control(
        "",
        Validators.required
      ),
      plumbing_drainage_water_plan: this._formBuilder.control(
        "",
        Validators.required
      ),
      electrical_telecom_mechanical_data: this._formBuilder.control(
        "",
        Validators.required
      ),
      fire_protection: this._formBuilder.control("", Validators.required),
      building_security: this._formBuilder.control("", Validators.required),
      bill_of_quantities: this._formBuilder.control(""),
      certifications: this._formBuilder.control("", Validators.required),
      prev_communication: this._formBuilder.control(""),
      additional_upload_1: this._formBuilder.control(""),
      additional_upload_2: this._formBuilder.control(""),
      additional_upload_3: this._formBuilder.control(""),
    }),
  });

  get UPI() {
    return this.form.get("plot_number");
  }

  get techSurveyDetails() {
    return this.form.get(
      "project_estimates.tech_survey_details"
    ) as unknown as FormArray;
  }

  get developmentDetails() {
    return this.form.get("development_details");
  }

  get projectEstimates() {
    return this.form.get("project_estimates");
  }

  get attachments() {
    return this.form.get("attachments");
  }

  trackCheckboxes(event: Event) {
    const checkboxElement = event.target as HTMLInputElement;
    const isChecked = checkboxElement?.checked;
    const value = checkboxElement?.value;
    if (isChecked && !this.techSurveyDetails.value.includes(value)) {
      this.techSurveyDetails.push(this._formBuilder.control(value));
    } else if (!isChecked && this.techSurveyDetails.value.includes(value)) {
      const index = this.techSurveyDetails.value.indexOf(value);
      this.techSurveyDetails.removeAt(index);
    }
  }

  ngOnInit(): void {}

  checkUPI(upi: any) {
    this.checkingUpi = true;
    this._newApplicationService.getUPIInfo(upi).subscribe(
      (result: any) => {
        if (result) {
          this.checkingUpi = false;
          this.checkUpiInfo = result.data;
          localStorage.setItem(
            "checkUpiInfo",
            JSON.stringify(this.checkUpiInfo)
          );
          result?.data?.plannedLandUses?.forEach((use: any) => {
            this.getRegulations(use?.landUseName);
          });
          console.log(this.regulations);
          this.developerInfo = result?.data?.representative;
          this.upiChecked = true;
        }
      },
      (error: any) => {
        this.checkingUpi = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  getRegulations(zoneName: any) {
    const zoneCode = zoneName.split("-")[0];

    if (!this.regulations[zoneName]) {
      this.regulations[zoneName] = {};
    }

    this._newApplicationService.getRegulations(zoneCode).subscribe(
      (result: any) => {
        if (result) {
          result.data.forEach((regulation: any) => {
            const useName = regulation.use.name;
            const regulationName = regulation.regulation.name;

            if (useName === "Permitted") {
              this.recordRegulation(
                this.regulations[zoneName],
                "permitted",
                regulationName
              );
            } else if (useName === "Prohibited") {
              this.recordRegulation(
                this.regulations[zoneName],
                "prohibited",
                regulationName
              );
            } else if (useName === "Conditional uses") {
              this.recordRegulation(
                this.regulations[zoneName],
                "conditional",
                regulationName
              );
            }
          });
        }
      },
      (error: any) => {
        let errorMessage = "Something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  recordRegulation(obj: any, key: string, value: any) {
    if (Array.isArray(obj[key])) {
      obj[key].push(value);
    } else {
      obj[key] = [value];
    }
  }

  getPermitTypes() {
    this._newApplicationService.getPermitTypes().subscribe(
      (result: any) => {
        if (result) {
          this.permitTypes = result.data;
          localStorage.setItem("permitTypes", JSON.stringify(this.permitTypes));
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  getProjectCategories() {
    this._newApplicationService.getProjectCategories().subscribe(
      (result: any) => {
        if (result) {
          this.projectCategories = result.data;
          localStorage.setItem(
            "projectCategories",
            JSON.stringify(this.projectCategories)
          );
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  getBuildingTypes() {
    this._newApplicationService.getBuildingTypes().subscribe(
      (result: any) => {
        if (result) {
          this.buildingTypes = result.data;
          localStorage.setItem(
            "buildingTypes",
            JSON.stringify(this.buildingTypes)
          );
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  getCountriesAndCodes() {
    this._countriesCodesService.getCountriesAndCodes().subscribe(
      (result: any) => {
        if (result) {
          this.countriesAndCodes = result.data;
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  addFile(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputFormControlName = inputElement.getAttribute("formControlName");

    if (inputElement.files && inputElement.files.length > 0) {
      this.form.patchValue({
        attachments: {
          [inputFormControlName as string]: inputElement.files[0],
        },
      });
    }
  }

  uploadAndSubmit(form: any) {
    this.submittingForm = true;
    const attachments = Object.keys(form.value.attachments);
    const uploadPromises: Promise<any>[] = [];

    attachments.forEach((attachment) => {
      const file = form.value.attachments[attachment];
      if (file instanceof File) {
        const uploadPromise = this._attachmentsService
          .uploadFile(file)
          .toPromise();
        uploadPromises.push(uploadPromise);
      }
    });

    if (uploadPromises.length > 0) {
      Promise.all(uploadPromises)
        .then((results: any[]) => {
          // Process the uploaded file results
          results.forEach((result, index) => {
            const attachment = attachments[index];
            if (result) {
              form.patchValue({
                attachments: {
                  [attachment]: result.data.url,
                },
              });
            }
          });

          this.submitApplication(form);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  submitApplication(form: any) {
    // const attachments = Object.keys(form.value.attachments);
    // const uploadPromises: Promise<any>[] = [];

    // attachments.forEach((attachment) => {
    //   const file = form.value.attachments[attachment];
    //   if (file) {
    //     const uploadPromise = this._attachmentsService
    //       .uploadFile(file)
    //       .toPromise();
    //     uploadPromises.push(uploadPromise);
    //   }
    // });

    // if (uploadPromises.length > 0) {
    //   Promise.all(uploadPromises)
    //     .then((results: any[]) => {
    //       // Process the uploaded file results
    //       results.forEach((result, index) => {
    //         const attachment = attachments[index];
    //         if (result) {
    //           form.patchValue({
    //             attachments: {
    //               [attachment]: result.data.url,
    //             },
    //           });
    //         }
    //       });

    //       submitApplication();
    //     })
    //     .catch((error: any) => {
    //       console.log(error);
    //     });
    // }
    // Process the application
    // Redirect to /applications
    form.patchValue({
      developer_details: {
        first_name: this?.developerInfo?.surname,
        last_name: this?.developerInfo?.foreNames,
      },
      district_id: this?.checkUpiInfo?.parcelLocation?.district?.districtCode,
      sector_id: this?.checkUpiInfo?.parcelLocation?.sector?.sectorCode,
    });

    this._newApplicationService.submitApplication(form.value).subscribe(
      (result: any) => {
        if (result) {
          this.submittingForm = false;
          this.nextStep();
          console.log(result);
        }
      },
      (error: any) => {
        this.submittingForm = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }
}
