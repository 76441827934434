<div class="container mt-3">
  <div *ngIf="loadingApplication; else body">
    <p class="text-center">Loading application details...</p>
  </div>
  <ng-template #body class="page-body">
    <section class="actions mb-2 d-flex justify-content-end" *ngIf="role !== 'APPLICANT'" >
      <button class="btn btn-primary" (click)="openAssignReviewerPopUp()">Assign to reviewer</button>
    </section>
    <section class="actions mb-2">
     <ul class="list-group list-group-horizontal">
  <li class="list-group-item item" (click)="downloadFilesAsZip(attachmentsArray, attachments, application?.id)">
    Download all files</li>
</ul>
    </section>
    <div class="card">
      <div class="card-header text-center">
        <h4>Application details</h4>
      </div>
      <div class="card-body">
        <div class="info-container">
          <h5><i class="bi bi-file-text-fill"></i> Application Info</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>Ref N<sup>o</sup>:</h6>
              <p>{{application?.id}}</p>
            </div>
            <div class="col">
              <h6>Billing status:</h6>
              <span class="badge bg-secondary">{{application?.billing_status}}</span>
            </div>
            <div class="col">
              <h6>Stage:</h6>
              <span class="badge   bg-primary">{{application.stage}}</span>
            </div>
            <div class="col">
              <h6>Submission date:</h6>
              <p>{{application.created_at}}</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="info-container">
          <h5><i class="bi bi-crop"></i> Plot Info</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>UPI</h6>
              <p>{{application?.plot_number}}</p>
            </div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
        <hr>
        <div class="info-container">
          <h5><i class="bi bi-person-fill"></i> Developer & Owner's details</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>Owner's names:</h6>
              <p>
                {{ application?.project_owner?.first_name }}
                {{ application?.project_owner?.last_name }}
              </p>
            </div>
            <div class="col">
              <h6>Owner's email:</h6>
              <p>{{ application?.project_owner?.email }}</p>
            </div>
            <div class="col">
              <h6>Owner's phone number:</h6>
              <p>{{ application?.project_owner?.phone_number }}</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6>Developer's names:</h6>
              <p>
                {{ application?.developer?.first_name }}
                {{ application?.developer?.last_name }}
              </p>
            </div>
            <div class="col">
              <h6>Developer's email:</h6>
              <p>{{ application?.developer?.email }}</p>
            </div>
            <div class="col">
              <h6>Developer's phone number:</h6>
              <p>{{ application?.developer?.phone_number }}</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="info-container">
          <h5><i class="bi bi-house-fill"></i> Project details</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>Name:</h6>
              <p>{{ application?.details?.project_name }}</p>
            </div>
            <div class="col">
              <h6>Permit type</h6>
              <p>{{ application?.details?.permit_type?.name }}</p>
            </div>
            <div class="col">
              <h6>Project category</h6>
              <p>{{ application?.project_category?.name }}</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6>Building type</h6>
              <p>{{ application?.building_type?.name }}</p>
            </div>
            <div class="col">
              <h6>Details:</h6>
              <p>
                {{ application?.details?.project_description}}
                 <!-- <span style="color:blue;cursor:pointer" (click)="openDialog('project description',application?.details?.project_description )">....read more</span> -->
              </p>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <hr />
        <div class="info-container">
          <h5><i class="bi bi-file-check-fill"></i> Development details</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>Plot size(in square meters):</h6>
              <p>{{ application?.details?.plot_size }}</p>
            </div>
            <div class="col">
              <h6>Built up area(in square meters):</h6>
              <p>{{ application?.details?.build_up_area }}</p>
            </div>
            <div class="col">
              <h6>Building Coverage:</h6>
              <p>
                {{
                  (+application?.details?.build_up_area * 100) /
                    +application?.details?.plot_size
                }}%
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6>proposed N<sup>o</sup> of floors:</h6>
              <p>{{ application?.details?.proposed_number_of_floors }}</p>
            </div>
            <div class="col">
              <h6>Gross floor area:</h6>
              <p>{{ application?.details?.gross_floor_area }}</p>
            </div>
            <div class="col">
              <h6>Gross floor area ratio:</h6>
              <p>{{ +application?.details?.gross_floor_area/+application?.details?.plot_size }}X</p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <h6>Number of parking spaces:</h6>
              <p>{{application?.details?.number_of_parking_spaces}}</p>
            </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
        <hr />
        <div class="info-container">
          <h5><i class="bi bi-collection-fill"></i> Project estimates</h5>
          <div class="row mb-2">
            <div class="col">
              <h6>Estimated water consumption/month(m<sup>3</sup>):</h6>
              <p>{{ application?.details?.monthly_water_consumption }}</p>
            </div>
            <div class="col">
              <h6>Estimated monthly electricity consumption/month(kWh):</h6>
              <p>{{ application?.details?.monthly_electricity_consumption }}</p>
            </div>
            <div class="col">
               <h6>Estimated distance to the nearest landline/ fiber optic cable(m):</h6>
              <p>{{ application?.details?.distance_to_fiber_or_landline }}</p>
            </div>
          </div>
          <div class="row mb-2">
<div class="col">
  <h6>Estimated project cost(rwf):</h6>
  <p>{{application?.details?.project_cost_rwf}} RWF</p>
</div>
<div class="col">
   <h6>Estimated project cost(USD):</h6>  
  <p>${{application?.details?.project_cost_usd}}</p>
</div>
<div class="col">
  <span *ngIf="application?.details?.tech_survey_details?.length === 0;else survey"></span>
  <ng-template #survey>
    <h6>Tech surveys to be carried out</h6>
   <p *ngFor="let survey of application?.details?.tech_survey_details">{{survey}},</p>
  </ng-template>
</div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <span *ngIf="!application?.details?.development_comments; else comments"></span>
              <ng-template #comments>
                <h6>additional comments</h6>
                <p>{{application?.details?.development_comments}}</p>
              </ng-template>
            </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
        <hr>
        <div class="info-container">
          <h5><i class="bi bi-tree-fill"></i> Environmental Impact Assessment(EIA)</h5>
          <div class="row mb-2">
            <h6 class="text-decoration-underline">1.Waste and production management</h6>
            <div class="col">
              <h6>Construction phase</h6>
              <p>{{application?.eia?.waste_production_mgt_construction_phase}}   
                <!-- <span style="color:blue;cursor:pointer" (click)="openDialog('waste production management(construction)',application?.eia?.waste_production_mgt_construction_phase)">....read more</span> -->
              </p>
            </div>
            <div class="col">
                <h6>Operational phase</h6>
              <p>{{application?.eia?.waste_production_mgt_operation_phase}}  
                 <!-- <span style="color:blue;cursor:pointer" (click)="openDialog('waste production management(operation)',application?.eia?.waste_production_mgt_operation_phase)">....read more</span> -->
                </p>
            </div>
          </div>
          <div class="row mb-2">
            <h6 class="text-decoration-underline">2.Likely impact and mitigation measure</h6>
            <div class="col">
              <h6>Construction phase</h6>
              <p>{{application?.eia?.waste_production_mgt_construction_phase}}  
                 <!-- <span style="color:blue;cursor:pointer" (click)="openDialog('waste production management(operation)',application?.eia?.waste_production_mgt_operation_phase)">....read more</span> -->
                </p>
            </div>
            <div class="col">
                <h6>Operational phase</h6>
              <p>{{application?.eia?.waste_production_mgt_operation_phase}}   
                <!-- <span style="color:blue;cursor:pointer" (click)="openDialog('waste production management(operation)',application?.eia?.waste_production_mgt_operation_phase)">....read more</span> -->
              </p>
            </div>
          </div>
        </div>
        <hr>
        <div class="info-container pb-5">
          <h5><i class="bi bi-paperclip"></i>Attachments</h5>
          <div>
            <h6>Proof of payments</h6>
            <div class="row">
              <p class="col-sm-4 d-inline">Receipt of property tax payment for the current year:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['property_tax_receipt'])">{{application?.attachments['property_tax_receipt']}}</a>
              </div>
            </div>
          </div>
          <br>
          <div>
            <h6>Location maps</h6>
            <div class="row">
              <p class="col-sm-4 d-inline">Plot location map:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['plot_location_map_1'])">{{application?.attachments['plot_location_map_1']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Plot location map 2:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['plot_location_map_2'])">{{application?.attachments['plot_location_map_2']}}</a>
              </div>
            </div>
            
          </div>
          <br>
          <div>
            <h6>Site analysis</h6>
            <div class="row">
              <p class="col-sm-4 d-inline">Site analysis:  </p> 
              <div class="col-sm-8">
                <a class="custom-link"  (click)="downloadFile(application?.attachments['site_analysis'])">{{application?.attachments['site_analysis']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Site analysis at the plot scale (approx. scale 1:5.000 -1:5.000 or 1:2.000 depending on plot size):  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['site_analysis_plot_scale'])">{{application?.attachments['site_analysis_plot_scale']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Design report:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['design_report'])">{{application?.attachments['design_report']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Environmental Impact Assessment Report or Clearance:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['eia_report'])">{{application?.attachments['eia_report']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Site plan:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['site_plan'])">{{application?.attachments['site_plan']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Geotechnical data:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['geo_technical_data'])">{{application?.attachments['geo_technical_data']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Architectural Data:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['architectural_data'])">{{application?.attachments['architectural_data']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Structural Data:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['structural_data'])">{{application?.attachments['structural_data']}}</a>
              </div>
            </div>
            <div class="row">
              <p class="col-sm-4 d-inline">Copy of green building minimum compliance report:  </p> 
              <div class="col-sm-8">
                <a class="custom-link" (click)="downloadFile(application?.attachments['green_building_min_compliance_report'])">{{application?.attachments['green_building_min_compliance_report']}}</a>
              </div>
            </div>
          </div>
          <br>
          <div>
            <h6>Building services</h6>
            <div class="row">
                <p class="col-sm-4 d-inline">Plumbing, Drainage, Storm water and Waste water data:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['plumbing_drainage_water_plan'])">{{application?.attachments['plumbing_drainage_water_plan']}}</a>
                </div>
            </div>
            <div class="row">
                <p class="col-sm-4 d-inline">Electrical, Telecommunication and Mechanical data:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['electrical_telecom_mechanical_data'])">{{application?.attachments['electrical_telecom_mechanical_data']}}</a>
                </div>
            </div>
          </div>
          <br>
          <div>
            <h6>Safety measure plan</h6>
              <div class="row">
                <p class="col-sm-4 d-inline">Fire Protection:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['fire_protection'])">{{application?.attachments['fire_protection']}}</a>
                </div>
            </div>
              <div class="row">
                <p class="col-sm-4 d-inline">Building security:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['building_security'])">{{application?.attachments['building_security']}}</a>
                </div>
            </div>
              <div class="row">
                <p class="col-sm-4 d-inline">Bill of Quantities:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['bill_of_quantities'])">{{application?.attachments['bill_of_quantities']}}</a>
                </div>
            </div>
              <div class="row">
                <p class="col-sm-4 d-inline">Certifications . Please print Self Certification, sign, scan and upload here. (refer to Chapter 5 of Checklist):  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['certifications'])">{{application?.attachments['certifications']}}</a>
                </div>
            </div>
              <div class="row">
                <p class="col-sm-4 d-inline">Any previous communication (Letters):  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['prev_communication'])">{{application?.attachments['prev_communication']}}</a>
                </div>
            </div>
          </div>
          <br>
          <div>
            <h6>Additional documents</h6>
             <div class="row">
                <p class="col-sm-4 d-inline">Additional upload 1:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['additional_upload_1'])">{{application?.attachments['additional_upload_1']}}</a>
                </div>
            </div>
             <div class="row">
                <p class="col-sm-4 d-inline">Additional upload 2:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['additional_upload_2'])">{{application?.attachments['additional_upload_2']}}</a>
                </div>
            </div>
             <div class="row">
                <p class="col-sm-4 d-inline">Additional upload 3:  </p> 
                <div class="col-sm-8">
                  <a class="custom-link" (click)="downloadFile(application?.attachments['additional_upload_3'])">{{application?.attachments['additional_upload_3']}}</a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
