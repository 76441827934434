import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AssignToReviewerComponent } from "../assign-to-reviewer/assign-to-reviewer.component";
import { ApplicationsService } from "src/app/services/applications.service";
import { ToasterService } from "src/app/services/toaster.service";
import { EventTypes } from "src/app/models/toast";

@Component({
  selector: "app-all-applications",
  templateUrl: "./all-applications.component.html",
  styleUrls: ["./all-applications.component.scss"],
})
export class AllApplicationsComponent implements OnInit {
  p: number = 1;
  applications:any

  constructor(private _dialogRef: MatDialog, private _applicationsService: ApplicationsService, private _toaster : ToasterService) {
    // this.applications = this.applications.map((application: any) => {
    //   const formattedDate = this.formatDate(application.created_at);
    //   const targetDate = new Date(formattedDate)
    //   const today = new Date();
    //   const duration = this.getDuration(targetDate,today)
    //   return { ...application, created_at: formattedDate, duration };
    // });
  }

  getDuration(targetDate: Date, today: Date) {
    const timeDifference = today.getTime() - targetDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  ngOnInit(): void {
     this._applicationsService.getApplications().subscribe(
       (result: any) => {
         if (result) {
           this.applications = result.map((application: any) => {
             const formattedDate = this.formatDate(application.created_at);
            const targetDate = new Date(formattedDate);
             const today = new Date();
             const duration = this.getDuration(targetDate, today);
             return { ...application, created_at: formattedDate, duration };
           });
         }
         console.log('apps',this.applications)
       },
       (error: any) => {
         let errorMessage = "something went wrong, try again!";
         if (typeof error === "string") {
           errorMessage = error;
         } else if (error && error.message) {
           errorMessage = error.message;
         }

         this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
       }
     );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }

  openDialog() {
    this._dialogRef.open(AssignToReviewerComponent, {
      data: { user: 1 },
      width: "60%",
    });
  }
}
