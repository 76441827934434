import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
})
export class DashboardLayoutComponent implements OnInit, OnChanges {
  role:string | null
  url: string[] = []
  
  constructor(public AuthService: AuthenticationService, private _router:Router) {
    this.role = localStorage?.getItem("role");
  }

  ngOnInit(): void {
    this.url = this._router.url.split("/")
    console.log(this.url)
  }

  ngOnChanges(): void {
    this.url = this._router.url.split("/");
    console.log(this.url)
  }

  signout() {
    this.AuthService.logout();
    this._router.navigate(["/"]);
  }
}
