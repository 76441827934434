<mat-toolbar class="d-flex justify-content-between custom-toolbar">
  <div class="text-center">
    <button
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
      (click)="drawer.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span>Kubaka</span>
  </div>
  <div>
    <a
      class="nav-link"
      aria-current="page"
      style="font-size: medium;cursor: pointer;"
      (click)="signout()"
      ><i class="bi bi-box-arrow-left"></i> Logout</a
    >
  </div>
</mat-toolbar>

<mat-drawer-container>
  <mat-drawer #drawer opened="true" mode="side" style="background-color: rgb(245, 245, 245);">
    <mat-nav-list>
      <mat-list-item class="no-hover-color-change" routerLink="/dashboard" [routerLinkActive]="'highlighted'" [routerLinkActiveOptions]="{exact:true}">
        <button mat-button ><mat-icon>dashboard</mat-icon> Dashboard</button>
      </mat-list-item>
      <mat-list-item class="no-hover-color-change" routerLink="user-management" *ngIf="role !== 'APPLICANT'" [routerLinkActive]="'highlighted'">
        <button mat-button ><mat-icon>person</mat-icon> User Management</button>
      </mat-list-item>
      <mat-list-item class="no-hover-color-change" routerLink="applications" *ngIf="role !== 'APPLICANT'" [routerLinkActive]="'highlighted'">
        <button mat-button ><mat-icon>book</mat-icon> All applications</button>
      </mat-list-item>
      <mat-list-item class="no-hover-color-change" routerLink="tasks" *ngIf="role !== 'APPLICANT'" [routerLinkActive]="'highlighted'">
        <button mat-button ><mat-icon>assignment</mat-icon> Tasks</button>
      </mat-list-item>
      <mat-list-item class="no-hover-color-change">
        <button mat-button><mat-icon>analytics</mat-icon> Analytics</button>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <div style="background-color: white;min-height: 88vh">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<footer class="footer py-3 fixed-bottom" style="background-color: #005fad">
  <div class="container text-center text-white">
    Copyright &copy; 2023 - Rwanda Housing Authority - All rights reserved
  </div>
</footer>
