import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class ApplicationsService {
  url = environment.backendUrl + "applications";
  token = localStorage.getItem("token");
  headers = new HttpHeaders().set("Authorization", `Bearer ${this.token}`);
  constructor(private _http: HttpClient) {}

  getApplications() {
    return this._http.get(this.url, { headers: this.headers }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((error) => {
        let errorMessage = "An unexpected error occurred.";
        if (error && error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
