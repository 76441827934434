export class FrenchResource{
      // GENERAL
      general_login_text = "Connectez-vous"
      // - GENERAL
  
     // Main Page
     main_page_title = "Autorisation De Bâtir";
     main_page_subtitle = "Suivez votre dossier ici";
     main_upi_search_placeholder = "Utilisez votre numéro UPI ";
     main_search_button = "Chercher"
     main_spinner_text = "En cours de recherche..."

     main_current_status_text = "Étape actuel";
    
    //--Results story
     results_date_specifier = "Le jour du"
     results_permit_type = "vous avez fait une demande d'autorization de bâtir de type ";
     results_permit_sector="dans le secteur de ";
     results_application_number_text="dont le numéro est "
     results_application_stage_text = "Votre demande est dans l'étape de "
     results_application_not_submitted_text = "Vous n'avez pas encore envoyé votre demande d'autorization. Envoyez le aujourdhui et commencez à suivre son etat!";
     results_permit_approved_text="Feliciation! Votre demande a eté approuvée le "
     results_permit_number_text = "avec le numéro d'autorisation "
     results_login_to_download = "dans votre compte pour le télécharger.";
     //!--Results stage

     // -- Application Stages
     application_stage_tittle="Les étapes de la demande"
     application_stage_submission = "Le Dépôt"
     application_stage_submission_text = "La toute premiere étape quand vous avez deposé la demande!"
     application_stage_review = "Au Cours De Révision"
     application_stage_review_text = "La demande est en cours de révision par les agents en charge."
     application_stage_architect = "Rétour chez l'Architecte"
     application_stage_architect_text = "Il y a un ou des problème(s) dans votre demande, et l'architecte doit faire quelques modification sur le dossier avant de rédeposer."
     application_stage_invoicing = "Facturation"
     application_stage_invoicing_text = "Tout est bon! L'architecte a déjà reçu un message pour payer les frais d'autorisation avant de le télécharger!"
     application_stage_issuance = "L'autorisation est prête"
     application_stage_issuance_text = "Votre autorisation a éte approuvée et elle est prête a être telecharger"
     //!-- Application Stages

    
     // END - Main Page
}