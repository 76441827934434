<p-toast></p-toast>
<div class=" d-flex align-items-center justify-content-center card-container p-4">
<div class="card d-flex m-3  flex-row">
    <div class="card-img-cont card-body d-flex flex-column text-center justify-content-center  d-none d-md-flex">
        <img class="card-img" src="https://res.cloudinary.com/duvzjjfba/image/upload/v1681994723/buildings_2_g60ltj.svg" alt="..."> <br>
      <h4 class="text-white">Take Control of Your Building <br> Permits with Kubaka</h4>
    </div>
  <div class="card-body">
    <form #form="ngForm" class="d-flex flex-column" style="height:100%;justify-content: center;" (ngSubmit)="signup(form)">
    <h5 class="card-title" style="text-align: center;">Welcome , Create an account</h5>
    <ul>
      <li>All fields marked with (*) are required</li>

    </ul>
    <div class="input-container row">
      <div class="mb-2 col-sm-6">
        <label for="first-name-input" class="form-label">First Name*</label>
        <input type="text" ngModel name="first_name" #firstName="ngModel" required class="form-control" id="first-name-input" placeholder="eg: Kalisa">
      </div>
      <div class="mb-2 col-sm-6">
        <label for="last-name-input" class="form-label">Last Name</label>
        <input type="text" ngModel name="last_name" #lastName="ngModel" class="form-control" id="last-name-input" placeholder="eg: Aimable">
      </div>
    </div>
     <div *ngIf="firstName.touched && !firstName.valid">
<p class="error">First name is required</p>
  </div>

    <div class="input-container row">
      <div class="mb-2 col-sm-6">
        <label for="email-input" class="form-label">Email address*</label>
        <input type="email" ngModel name="email" #email="ngModel" required class="form-control" id="email-input" placeholder="eg: example@email.com" aria-describedby="emailHelp">
      </div>
      <div class="mb-2 col-sm-6">
        <label for="phone-input" class="form-label">Phone(with country code)*</label>
        <input type="text" ngModel name="phone_number" #phone="ngModel" required class="form-control" id="phone-input" placeholder="eg: +250786578140">
      </div>
    </div>
      <div *ngIf="(email.touched &&phone.touched) && (!email.valid || !phone.valid)">
<p class="error"> Both email and phone number are required</p>
  </div>
    <div class="input-container row">
      <div class="mb-2 col-sm-6">
        <label for="password-input" class="form-label">Password*</label>
        <input type="password" ngModel name="password" #password="ngModel" minlength="6" required class="form-control" id="password-input">
      </div>
      <div class="mb-2 col-sm-6">
        <label for="comfirm-password" class="form-label">Confirm password*</label>
        <input type="password" ngModel name="cpassword" #cpassword="ngModel" required class="form-control" id="comfirm-password">
      </div>
    </div>
      <div *ngIf="password.touched && password.errors?.['minlength']">
<p class="error"> password must be atleast 6 characters</p>
  </div>
      <div *ngIf="(password.touched || cpassword.touched) && (!password.valid || !cpassword.valid) || (password.value !== cpassword.value)">
<p class="error"> Both passwords are required and must match</p>
  </div>
  <div class="submit text-center">
      <button
            type="submit"
            class="btn btn-primary align-items-center"
            style="width: 100%;"
            [disabled]="!form.valid || signingUp"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="signingUp; else default"
            ></span>
            <ng-template #default> Signup </ng-template></button
          >
      <p class="mt-1">have an account? <a class="text-primary" routerLink="/login" style="text-decoration: none;">Login</a></p>
  </div>

</form>
  </div>
</div>
</div>