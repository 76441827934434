import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthenticationService
  ) {}

  canActivate() {
    if (this._authService.isLoggedIn()) return true;

    this._router.navigate(["/login"]);
    return false;
  }
}
