import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { ToasterService } from "src/app/services/toaster.service";
import { EventTypes } from "src/app/models/toast";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  signedUp: boolean = false;
  signingUp: boolean = false;

  constructor(
    private _authservice: AuthenticationService,
    private _router: Router,
    private _toaster: ToasterService
  ) {}

  signup(credentials: any) {
    const signUpData = { ...credentials.value };
    delete signUpData.cpassword;

    this.signingUp = true;
    console.log("credentials", signUpData);
    this._authservice.signup(signUpData).subscribe(
      (result: any) => {
        if (result) {
          this.signedUp = true;
          this.signingUp = false;
          this._toaster.showToast(
            EventTypes.Success,
            "Success",
            "you have successfully created your account, redirecting to login page in few seconds",
            5000
          );
          setTimeout(() => {
            this._router.navigate(["/login"]);
          }, 5000);
          return;
        } else {
          this.signedUp = false;
        }
      },
      (error: any) => {
        this.signingUp = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  ngOnInit(): void {}
}
