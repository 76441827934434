<div class="p-3">
  <h4 class="mat-dialog-title text-center">Assign Role</h4>
  <hr />
  <div class="mat-dialog-content" *ngIf="!assignedRole; else success">
    <p class="text-center" style="font-size: large;">assign role to user: <span style="font-weight: 450;">{{ user?.first_name }} {{ user?.last_name }}</span></p>
    <div class="input-conatiner">
      <label for="role">Role</label>
    <select name="role" id="role" class="form-select mb-2" (change)="setRole($event)">
      <option value="REVIEWER">REVIEWER</option>
      <option value="OWNER">OWNER</option>
      <option value="ENGINEER">ENGINEER</option>
      <option value="INSPECTOR">INSPECTOR</option>
      <option value="ADMIN">ADMIN</option>
      <option value="DIRECTOR">DIRECTOR</option>
    </select>
    </div>
    <div class="input-conatiner" *ngIf="role == 'DIRECTOR'">
      <label for="role">Agency</label>
    <select name="role" id="role" class="form-select mb-2" (change)="setAgency($event)">
      <option *ngFor="let agency of agencies" [value]="agency?.id">{{agency?.name}}</option>
    </select>
    </div>
    <div class="input-conatiner" *ngIf="role == 'REVIEWER'">
       <label for="role">Groups</label>
    <select name="role" id="role" class="form-select mb-2" (change)="setAgency($event)">
      <option  *ngFor="let group of reviewerGroups" [value]="group?.id">{{group?.name}}</option>  
      </select>
    </div>

    <button class="btn btn-primary" (click)="assignRole(role, user?.id)">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="assigningRole; else default"
      ></span>
      <ng-template #default> Assign Role </ng-template> 
    </button>
  </div>

  <ng-template #success>
    <div class="step-container" style="text-align: center">
      <h5>
        User {{ user?.first_name }} {{ user?.last_name }} is assigned role
        {{ role }}
      </h5>
      <i
        class="bi bi-check-circle"
        style="font-size: 4rem; color: rgb(46, 156, 46)"
      ></i
      ><br />
      <button class="btn btn-primary" (click)="onNoClick()">OK</button>
    </div>
  </ng-template>
</div>
