import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnglishResource } from 'src/app/resources/english.resource';
import { CachingService } from 'src/app/services/caching.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
 upi: string = "";
 searchResults:any = {}
 alert = "";
 spinner_on = false;

 locale: EnglishResource // Language support
 language_preference = "kinya";

  constructor(
    private _http: HttpClient,
    private _activatedRoute: ActivatedRoute,
    private _lang: LocalizationService,
    private caching: CachingService
    ) {
      this._lang = new LocalizationService;
      this.locale = new this._lang.KIN;
      this.language_preference = this.caching.getLanguage() ? this.caching.getLanguage() : "kinya";

      switch(this.language_preference){
        case "kinya": this.locale = new this._lang.KIN; break;
        case "eng": this.locale = new this._lang.EN; break;
        case "fren": this.locale = new this._lang.FREN; break;
      }
    }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(parameter => {
      this.upi = parameter['upi'];
    })
    if(this.upi){
      this.search()
    }
  }

  search() {
    if(this.upi.split('/').length === 5){
      this.spinner_on = true;
      this.reset()
      let url = `https://api.kubaka.gov.rw/bpmis1-0-api/applications?upi=${this.upi}`
      // let url = `http://localhost:4010/bpmis1-0-api/applications?upi=${this.upi}`
      this._http.get<any>(url).subscribe(response=>{
        this.spinner_on = false;
        if(response.success){
          // Sort results by date
          response.data.sort((a: { application_date: string | number | Date; },b: { application_date: string | number | Date; })=>{
            return new Date(b.application_date).valueOf() - new Date(a.application_date).valueOf();
          });
          this.searchResults = response.data[0]
        }else{
          this.alert = response.message;
          this.searchResults = {}
        }
      }, error=>{
      this.alert = 'There was an error. Please try again!'
      this.spinner_on = false;
      });
    }else{
      this.alert = 'Sorry! Wrong UPI Format. The right format is: X/XX/XX/XX/XXXX';
    }
  }

  setPreferedLanguage(language: string){
    this.caching.setLanguage(language);
    window.location.reload()
  }

  trimWhiteSpaces(){
    this.upi = this.upi.trim()
  }
  reset(){
    this.searchResults = {}
    this.alert = ""
  }

  formatStage(stage: string){
    if(stage){
      if(stage.indexOf('nspection') !== -1 || stage.indexOf('RHA') !== -1){
        return "In-"
      }else{
        return stage.slice(0,3);
      }
    }else{
      return ""
    }
  }
}