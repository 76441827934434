import { Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { FormBuilder } from "@angular/forms";
import { NewApplicationService } from "src/app/services/new-application.service";
import { ToasterService } from "src/app/services/toaster.service";
import { EventTypes } from "src/app/models/toast";

@Component({
  selector: "app-check-eligibility",
  templateUrl: "./check-eligibility.component.html",
  styleUrls: ["./check-eligibility.component.scss"],
  animations: [
    trigger("fadeScale", [
      transition(":enter", [
        style({ opacity: 0, transform: "scale(0.5)" }),
        animate(
          "0.5s ease-in-out",
          style({ opacity: 1, transform: "scale(1)" })
        ),
      ]),
      transition(":leave", [
        animate(
          "0.5s ease-in-out",
          style({ opacity: 0, transform: "scale(0.5)" })
        ),
      ]),
    ]),
  ],
})
export class CheckEligibilityComponent implements OnInit {
  regulations: any = {};
  upiChecked = false;
  checkingUpi = false;
  checkUpiInfo: any;
  developerInfo: any = "";

  constructor(
    private _formBuilder: FormBuilder,
    private _newApplicationService: NewApplicationService,
    private _toaster: ToasterService
  ) {}

  form = this._formBuilder.group({
    plot_number: this._formBuilder.control("", Validators.required),
  });

  get UPI() {
    return this.form.get("plot_number");
  }

  checkUPI(upi: any) {
    //check if the upi is already checked and return or if the checked upi is different from the one trying to be checked clear the regulation object
    if (this.upiChecked && upi == this.checkUpiInfo?.upi) {
      return;
    } else if (this.upiChecked && upi !== this.checkUpiInfo?.upi) {
      this.regulations = {};
    }
    this.checkingUpi = true;
    this._newApplicationService.getUPIInfo(upi).subscribe(
      (result: any) => {
        if (result) {
          console.log(result);
          this.checkingUpi = false;
          this.checkUpiInfo = result.data;
          result?.data?.plannedLandUses?.forEach((use: any) => {
            this.getRegulations(use?.landUseName);
          });
          this.developerInfo = result?.data?.representative;
          this.upiChecked = true;
        }
      },
      (error: any) => {
        this.checkingUpi = false;
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  getRegulations(zoneName: any) {
    const zoneCode = zoneName.split("-")[0];

    if (!this.regulations[zoneName]) {
      this.regulations[zoneName] = {};
    }

    this._newApplicationService.getRegulations(zoneCode).subscribe(
      (result: any) => {
        if (result) {
          result.data.forEach((regulation: any) => {
            const useName = regulation.use.name;
            const regulationName = regulation.regulation.name;

            if (useName === "Permitted") {
              this.recordRegulation(
                this.regulations[zoneName],
                "permitted",
                regulationName
              );
            } else if (useName === "Prohibited") {
              this.recordRegulation(
                this.regulations[zoneName],
                "prohibited",
                regulationName
              );
            } else if (useName === "Conditional uses") {
              this.recordRegulation(
                this.regulations[zoneName],
                "conditional",
                regulationName
              );
            }
          });
        }
      },
      (error: any) => {
        let errorMessage = "Something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }
        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  recordRegulation(obj: any, key: string, value: any) {
    if (Array.isArray(obj[key])) {
      obj[key].push(value);
    } else {
      obj[key] = [value];
    }
  }

  ngOnInit(): void {}
}
