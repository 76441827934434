import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventTypes } from "src/app/models/toast";
import { ToasterService } from "src/app/services/toaster.service";
import { UsersService } from "src/app/services/users.service";

@Component({
  selector: "app-assign-to-reviewer",
  templateUrl: "./assign-to-reviewer.component.html",
  styleUrls: ["./assign-to-reviewer.component.scss"],
})
export class AssignToReviewerComponent implements OnInit {
  p: number = 1;
  reviewers: any = [
  ];
  searchQuery: string = "";
  searchResults: any;
  selectedReviewers: any = [];

  sortReviewers(event: any, reviewer: any) {
    if (event.target.checked) {
      this.selectedReviewers.push(reviewer);
    }

    if (!event.target.checked) {
      this.selectedReviewers.splice(
        this.selectedReviewers.indexOf(reviewer),
        1
      );
    }
    this.reviewers = this.reviewers.sort((a: any, b: any) => {
      if (a.checked && !b.checked) {
        return -1;
      } else if (!a.checked && b.checked) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  constructor(
    private _usersServices: UsersService,
    private _toaster: ToasterService,
    private _dialogRef: MatDialogRef<AssignToReviewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  clicked(data: any) {
    console.log(data);
  }
  onSearchInputChange(event: any) {
    this.searchQuery = event.target.value;
    if (this.searchQuery.length >= 3) {
      this.searchResults = this.reviewers.filter((user: any) => {
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    } else {
      this.searchResults = this.reviewers;
    }
  }

  ngOnInit(): void {
    this._usersServices.getAllUsers().subscribe(
      (result: any) => {
        if (result) {
          this.reviewers = result.data.map((reviewer: any) => {
            return { ...reviewer, checked: false };
          });
          this.searchResults = this.reviewers;
        }
      },
      (error: any) => {
        let errorMessage = "something went wrong, try again!";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error && error.message) {
          errorMessage = error.message;
        }

        this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
      }
    );
  }

  onNoClick(): void {
    this._dialogRef.close();
  }
}
