<p-toast></p-toast>
<div class="container p-5">
  <h4>Applications</h4>

  <div class="table-responsive">
    <table
      class="table table-striped border rounded"
      style="vertical-align: middle"
    >
      <thead>
        <tr>
          <th scope="col" nowrap>Processing Office</th>
          <th scope="col" nowrap>Ref. No</th>
          <th scope="col" nowrap>Bill Status</th>
          <th scope="col" nowrap>Stage</th>
          <th scope="col" nowrap>Submitted On</th>
          <th scope="col" nowrap>Action</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let application of applications
              | paginate : { itemsPerPage: 10, currentPage: p }
          "
          [ngClass]="{
            'late' : application?.duration >= 20,
            'mid': application?.duration >= 10 && application?.duration < 20,
            'early': application?.duration < 10
          }"
        >
          <td>{{ application.district.name }}</td>
          <td nowrap>{{ application?.id }}</td>
          <td><span class="badge bg-secondary">{{ application.billing_status }}</span></td>
          <td><span class="badge bg-primary">{{ application.stage }}</span></td>
          <td>{{ application.created_at }}</td>
          <td>
            <button
              class="btn"
              type="button"
              style="margin-left: 2px"
              routerLink="/dashboard/applications-details/{{ application.id }}"
            >
              <i class="bi bi-three-dots"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>