import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { NgxPaginationModule } from "ngx-pagination";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SearchComponent } from "./components/search/search.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { ApplicationsComponent } from "./components/applications/applications.component";
import { NewApplicationComponent } from "./components/new-application/new-application.component";
import { ApplicationDetailsComponent } from "./components/application-details/application-details.component";
import { LoginComponent } from "./components/login/login.component";
import { SignupComponent } from "./components/signup/signup.component";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { PopupComponent } from "./components/popup/popup.component";
import { UserManagementComponent } from "./components/user-management/user-management.component";
import { HomeLayoutComponent } from "./home-layout/home-layout.component";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { AssignRolePopupComponent } from "./components/assign-role-popup/assign-role-popup.component";
import { AllApplicationsComponent } from "./components/all-applications/all-applications.component";
import { AssignToReviewerComponent } from "./components/assign-to-reviewer/assign-to-reviewer.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ReviewApplicationsComponent } from "./components/review-applications/review-applications.component";
import { TasksComponent } from './components/tasks/tasks.component';
import { CheckEligibilityComponent } from './components/check-eligibility/check-eligibility.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    HeaderComponent,
    ApplicationsComponent,
    NewApplicationComponent,
    ApplicationDetailsComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PopupComponent,
    UserManagementComponent,
    HomeLayoutComponent,
    DashboardLayoutComponent,
    AssignRolePopupComponent,
    AllApplicationsComponent,
    AssignToReviewerComponent,
    DashboardComponent,
    ReviewApplicationsComponent,
    TasksComponent,
    CheckEligibilityComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem("token");
        },
      },
    }),
    ToastModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    NgxPaginationModule,
  ],
  providers: [JwtHelperService, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
