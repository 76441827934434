<div class="p-3">
  <h4 class="mat-dialog-title">{{title}}</h4>
  <hr />
  <div class="mat-dialog-content">
    <p>
   {{details}}
    </p>
    <button class="btn btn-primary" (click)="onNoClick()">Ok</button>
  </div>
</div>
