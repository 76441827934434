<p-toast></p-toast>
<div class="container p-5">
    <div class="page-header">
        <h1 class="page-heading">My Applications</h1>
        <div class="breadcrumbs"></div>
    </div>
    <div class="page-body">
        <div class="card">
            <!-- <div class="card-header"></div> -->
            <ng-template #default>
                <div class="card-body text-center">
                    <h3>you don't have any applications</h3>
                      <a class="tn btn-primary" style="  text-decoration: none;
                        display: inline-block;
                        padding: 7px;
                        border-radius: 4px;
                        margin-left: 12px;" routerLink="/new-application"><i class="bi bi-plus-square"></i> New Application</a>
                </div>

            </ng-template>
            <div class="card-body table-responsive" *ngIf="applications.length > 0;else  default">
                <table class="table table-hover table-striped" style="vertical-align: middle;">
                    <thead>
                        <tr>
                            <th scope="col" nowrap>Processing Office</th>
                            <th scope="col" nowrap>Ref. No</th>
                            <th scope="col" nowrap>Bill Status</th>
                            <th scope="col" nowrap>Stage</th>
                            <th scope="col" nowrap>Submitted On</th>
                            <th scope="col" nowrap>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let application of applications">
                            <td><a routerLink="/application-details">{{application.district.name}}</a></td>
                            <td nowrap>{{application.id}}</td>
                            <td><span class="badge bg-secondary">{{application.billing_status}}</span></td>
                            <td><span class="badge bg-primary">{{application.stage}}</span></td>
                            <td nowrap>{{application.created_at}}</td>
                            <td>
                                <a class="btn btn-outline-secondary" style="margin-left:2px;" routerLink="/application-details/{{application.id}}"><i class="bi bi-pencil"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>