<p-toast></p-toast>
<div
  class="d-flex align-items-center m-3 justify-content-center"
  style="height: 79vh"
>
  <div class="card d-flex rounded flex-row">
    <div
      class="card-body card-img-cont d-flex flex-column text-center justify-content-center d-none d-md-flex"
    >
      <img
        src="https://res.cloudinary.com/duvzjjfba/image/upload/v1682517183/unlock-fill_1_s2eexc.svg"
        alt="..."
        style="height: 30%"
      />
      <br />
      <h4 class="text-white">
        Take Control of Your Building <br />
        Permits with Kubaka
      </h4>
    </div>
    <div class="card-body" style="text-align: middle">
      <form
        #form="ngForm"
        class="d-flex flex-column"
        style="height: 100%; justify-content: center"
        (ngSubmit)="resetPassword(form)"
      >
        <h5 class="card-title" style="text-align: center">
          Create new password
        </h5>
        <div class="mt-2">
          <label for="otp" class="form-label" style="text-align: center"
            >Enter your password reset code</label
          >
          <input
            type="text"
            class="form-control"
            id="otp"
            ngModel
            name="otp"
            #otp="ngModel"
            required
          />
           <div *ngIf="otp.touched && !otp.valid">
          <p class="error" *ngIf="otp?.errors?.['required']">reset code is required</p>
        </div>
        </div>
        <div class="mt-2">
          <label for="otp" class="form-label" style="text-align: center"
            >Enter your new password</label
          >
          <input
            type="password"
            class="form-control"
            id="password"
            ngModel
            name="password"
            #password="ngModel"
            required
          />
        </div>
        <div class="mt-2">
          <label for="otp" class="form-label" style="text-align: center"
            >Comfirm password</label
          >
          <input
            type="password"
            class="form-control"
            id="comfirm-password"
            ngModel
            name="comfirmPassword"
            #comfirmPassword="ngModel"
            required
          />
        </div> 
           <div *ngIf="(comfirmPassword.touched || password.touched)  && (!password.valid || !comfirmPassword.valid)">
          <p class="error" *ngIf="otp?.errors?.['required']">both passwords are required</p>
        </div>
        <div *ngIf="passwordMismatch">
          <p class="error">the passwords do not match</p>
        </div>

        <div class="submit text-center mt-2">
          <button type="submit" class="btn btn-primary align-items-center" style="width:100%" [disabled]="!(otp.valid && password.valid && comfirmPassword.valid)">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="resetting; else default"
            ></span>
            <ng-template #default> Reset password </ng-template></button
          ><br />
        </div>
      </form>
    </div>
  </div>
</div>
