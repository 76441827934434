import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toaster.service';
import { EventTypes } from 'src/app/models/toast';

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetting: boolean = false;
  passwordMismatch: boolean = false;

  constructor(
    public authService: AuthenticationService,
    private _router:Router,
    private _toaster: ToasterService
  ) {}
  ngOnInit(): void {}


  resetPassword(form:any) {
    if (form.value.password !== form.value.comfirmPassword) {
      this.passwordMismatch = true;
      return ;
    }
    this.passwordMismatch = false;
    this.resetting = true;
    this.authService.resetPassword({otp: Number(form.value.otp),password:form.value.password}).subscribe(
      (result: any) => {
        if (result) {
          this.resetting = false;
          console.log(result);
          this._toaster.showToast(
            EventTypes.Success,
            "Success",
            "password reset succecfully, redirecting to login in few seconds",
            7000
          );

          setTimeout(() => {
            this._router.navigate(["/login"]);
          }, 7000);
        }
      },
      (error: any) => {
        this.resetting = false;
       let errorMessage = "something went wrong, try again!";
       if (typeof error === "string") {
         errorMessage = error;
       } else if (error && error.message) {
         errorMessage = error.message;
       }
       this._toaster.showToast(EventTypes.Error, "Error", errorMessage);
          
      }
    );
  }
}
