<nav class="navbar navbar-expand-lg shadow bg-body-tertiary rounded bg-light">
  <div class="container-fluid">
    <button *ngIf="dashboard" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <a class="navbar-brand fw-bold" href="#"
      ><i
        class="bi bi-building"
        *ngIf="!dashboard"
        style="font-size: 23px;"
      ></i>
      Kubaka</a
    >
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a
            class="nav-link btn"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            href="#"
            style="
              display: inline-block;
              padding: 7px;
              color: black;
              font-size: 1.1em;
              border-radius: 4px;
              margin-left: 12px;
            "
            routerLink="eligibility"
            ><i class="bi building-check"></i> Check eligibility</a
          >
        </li>
        <li class="nav-item" *ngIf="AuthService.isLoggedIn()">
          <a
            class="nav-link btn"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            href="#"
            style="
              display: inline-block;
              padding: 7px;
              border-radius: 4px;
              text-decoration: none;
              font-size: 1.1em;
              color: black;
              margin-left: 12px;
            "
            routerLink="/applications"
            >Applications</a
          >
        </li> -->
        <!-- <li class="nav-item">
          <a
            class="nav-link btn btn-primary"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            href="#"
            style="
              border: 1px solid #015fad;
              display: inline-block;
              padding: 7px;
              /* color: white; */
              border-radius: 4px;
              margin-left: 12px;
            "
            routerLink="new-application"
            ><i class="bi bi-plus-square"></i> New Application</a
          >
        </li> -->
      </ul>
      <div class="d-flex">
        <ul class="navbar-nav">
          <li class="nav-item">
              <a
              class="nav-link"
              aria-current="page"
              href="https://bpmis.gov.rw/index.php/login"
              target="_blank"
              style="border-radius: 4px; margin-left: 12px"
              ><i class="bi bi-person-circle"></i> Login</a
            >
          </li>
          <!-- <li class="nav-item" *ngIf="!AuthService.isLoggedIn(); else logout">
            <a
              class="nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-current="page"
              href="https://bpmis.gov.rw/index.php/login"
              target="_blank"
              style="border-radius: 4px; margin-left: 12px"
              ><i class="bi bi-person-circle"></i> Login</a
            >
          </li>

          <ng-template #logout>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-current="page"
                (click)="signout()"
                style="border-radius: 4px; margin-left: 12px; cursor: pointer"
                ><i class="bi bi-box-arrow-left"></i> Logout</a
              >
            </li>
          </ng-template> -->
        </ul>
      </div>
    </div>
  </div>
</nav>
