<p-toast></p-toast>
<div
  class="d-flex align-items-center m-3 justify-content-center"
  style="height: 79vh"
>
  <div class="card d-flex rounded flex-row">
    <div
      class="card-body card-img-cont d-flex flex-column text-center justify-content-center d-none d-md-flex"
    >
      <img
        src="https://res.cloudinary.com/duvzjjfba/image/upload/v1682495366/Group_4_wpxszk.svg"
        alt="..."
        style="height: 30%"
      />
      <br />
      <h4 class="text-white">
        Take Control of Your Building <br />
        Permits with Kubaka
      </h4>
    </div>
    <div class="card-body" style="text-align: middle">
      <form
        #form="ngForm"
        class="d-flex flex-column"
        style="height: 100%; justify-content: center"
        (ngSubmit)="forgotPassword(form.value)"
      >
        <h5 class="card-title" style="text-align: center">
          Trouble logging in?
        </h5>
        <div class="mt-2">
          <label for="email-input" class="form-label" style="text-align: center"
            >Provide your email address or phone number to receive a code that
            will help you get back access to your account</label
          >
          <input
            type="email"
            class="form-control"
            id="email-input"
            ngModel
            name="email_or_phone"
            #email="ngModel"
            placeholder="eg: example@email.com"
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div *ngIf="email.touched && !email.valid">
          <div class="error" *ngIf="email?.errors?.['required']">email or phone number is required</div>
        </div>

        <div class="submit text-center mt-2">
          <button type="submit" class="btn btn-primary align-items-center" style="width:100%" [disabled]="!email.valid">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="submitting; else default"
            ></span>
            <ng-template #default> Send code </ng-template></button
          ><br />
           <p class="mt-1">
            Received the code?
            <a
              class="text-primary"
              routerLink="/reset"
              style="text-decoration: none"
              >Reset your password</a
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
