<p-toast></p-toast>
<div class="container p-5">
  <nav class="nav">
  <a class="nav-link" [ngClass]="{active: currentTab === 'commentSheet'}" (click)="setTab('commentSheet')" aria-current="page">Comment sheet</a>
  <a class="nav-link" [ngClass]="{active: currentTab === 'taskDetails'}" (click)="setTab('taskDetails')">Task details</a>
</nav>
<br>
<br />
<form action="" [formGroup]="form" *ngIf="currentTab === 'commentSheet'; else taskDetails">
    <h5>~DISTRICT~ OSC Building Permit Officer Comment Sheet</h5>
    <div class="section">
      <h6>1. Checklist</h6>
      <div class="sub-section">
        <p>a. Project information</p>
        <div>
          <div class="row mb-3">
            <div class="col-sm-4">
              <label
                >a. Developer's name and contacts have been provided?</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="developer_contacts_approved"
                id="developer_info"
                formControlName="developer_contacts_approved"
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="developer_contacts_approved"
                formControlName="developer_contacts_approved"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="developer_contacts_approved_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label
                >b. Plot Location has been provided?</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="plot_location_provided"
                formControlName="plot_location_provided"
                value=true
              />
              <label for="plot_location_provided">Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="plot_location_provided" 
                formControlName="plot_location_provided"
                id=""
                value=false
              />
              <label for="plot_location_provided">No</label>
              <input type="text" class="form-control" formControlName="plot_location_provided_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label for=""
                >c. Plot data has been provided?</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="plot_data_provided"
                formControlName="plot_data_provided"
                value=true
              />
              <label >Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="plot_data_provided"
                formControlName="plot_data_provided"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="plot_data_provided_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label for=""
                >e. Land ownership documents attached?</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="land_ownership_data_provided"
                formControlName="land_ownership_data_provided"
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="land_ownership_data_provided"
                formControlName="land_ownership_data_provided"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="land_ownership_data_provided_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label
                >e. Proof of payments for property taxes for the current year
                attached.?</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="tax_payment_proof_attached"
                formControlName="tax_payment_proof_attached"
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="tax_payment_proof_attached"
                formControlName="tax_payment_proof_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="tax_payment_proof_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>f. Location maps attached?</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="location_maps_attached"
                formControlName="location_maps_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="location_maps_attached"
                formControlName="location_maps_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="location_maps_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>g. Site analysis attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="site_analysis_attached"
                formControlName="site_analysis_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="site_analysis_attached"
                formControlName="site_analysis_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="site_analysis_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>h. Design report attached?</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="design_report_attached"
                formControlName="design_report_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="design_report_attached"
                formControlName="design_report_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="design_report_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label
                >i. Environmental Impact Assessment Report or Clearance
                attached</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="eia_report_attached"
                formControlName="eia_report_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="eia_report_attached"
                formControlName="eia_report_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="eia_report_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>j. Site plan attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="site_plan_attached"
                formControlName="site_plan_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="site_plan_attached"
                formControlName="site_plan_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="site_plan_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>k. Landscape Plan attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="landscape_plan_attached"
                formControlName="landscape_plan_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="landscape_plan_attached"
                formControlName="landscape_plan_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="landscape_plan_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>l. Geotechnical data attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="geotechnical_plan_attached"
                formControlName="geotechnical_plan_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="geotechnical_plan_attached"
                formControlName="geotechnical_plan_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="geotechnical_plan_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>m. Architectural data attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="architectural_data_attached"
                formControlName="architectural_data_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="architectural_data_attached"
                formControlName="architectural_data_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="architectural_data_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>n. Structural data attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="structural_data_attached"
                formControlName="structural_data_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="structural_data_attached"
                formControlName="structural_data_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="structural_data_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>o. Building services attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="building_services_attached"
                formControlName="building_services_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="building_services_attached"
                formControlName="building_services_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control"formControlName="building_services_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label
                >p. Safety measure plan attached</label
              >
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="safety_measures_plan_attached"
                formControlName="safety_measures_plan_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="safety_measures_plan_attached"
                formControlName="safety_measures_plan_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="safety_measures_plan_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>q. Bill of quantities attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="bill_of_quantities_attached"
                formControlName="bill_of_quantities_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="bill_of_quantities_attached"
                formControlName="bill_of_quantities_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="bill_of_quantities_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>r. Certifications attached</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="certifications_attached"
                formControlName="certifications_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="certifications_attached"
                formControlName="certifications_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="certifications_attached_comment" placeholder="comment" />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-sm-4">
              <label>s. Other documents</label>
            </div>
            <div class="col-sm-8">
              <input
                type="radio"
                class="m-1 mb-2"
                name="other_documents_attached"
                formControlName="other_documents_attached"
                id=""
                value=true
              />
              <label>Yes</label>
              <input
                type="radio"
                class="m-1 mb-2"
                name="other_documents_attached"
                formControlName="other_documents_attached"
                id=""
                value=false
              />
              <label>No</label>
              <input type="text" class="form-control" formControlName="other_documents_attached_comment" placeholder="comment" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h6>2. Data and assessment</h6>
      <div class="sub-section">
        <p>a. Site visit and analysis</p>
        <div class="row mb-3">
          <div class="col-sm-4">
            <label
              >i. Site visit pictures (Please zip all and attach here)</label
            >
          </div>
          <div class="col-sm-8">
            <input type="file" (change)="addFile($event)" formControlName="site_visit_pictures" class="form-control" />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>ii. Site analysis</label>
          </div>
          <div class="col-sm-8">
            <input type="file" (change)="addFile($event)" formControlName="site_analysis" class="form-control" />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>iii. Other attachments</label>
          </div>
          <div class="col-sm-8">
            <input type="file" (change)="addFile($event)" formControlName="other_attachments" class="form-control" />
          </div>
        </div>
      </div>

      <div class="sub-section">
        <p>b. comments</p>
        <div class="row mb-3">
          <div class="col-sm-4">
            <label>i. Urban planning comments</label>
          </div>
          <div class="col-sm-8">
            <textarea
              name=""
              class="form-control"
              formControlName="final_urban_planning_comments"
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>ii. Architectural comments</label>
          </div>
          <div class="col-sm-8">
            <textarea
              name=""
              class="form-control"
              formControlName="final_architectural_comments"
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label
              >iii. Civil engineering / Structural comments</label
            >
          </div>
          <div class="col-sm-8">
            <textarea
              name=""
              class="form-control"
              formControlName="final_structural_comments"
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h6>3. Final decision</h6>
      <div class="sub-section">
        <div class="row mb-3">
          <div class="col-sm-4">
            <label>a. Conditions of Approval</label>
          </div>
          <div class="col-sm-8">
            <textarea
              name=""
              class="form-control"
              formControlName="final_conditional_approval"
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>b. Decision</label>
          </div>
          <div class="col-sm-8">
            <textarea
              name=""
              class="form-control"
              formControlName="final_decision"
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>c. Attach letter</label>
          </div>
          <div class="col-sm-8">
            <input type="file" name="" formControlName="final_attach_letter" (change)="addFile($event)" id="" class="form-control" />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>d. Other attachments</label>
          </div>
          <div class="col-sm-8">
            <input type="file" formControlName="final_attachments" (change)="addFile($event)" name="" id="" class="form-control" />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <h6>4. Additional uploads</h6>

      <div class="row mb-3">
        <div class="col-sm-4">
          <label>a. Upload File</label>
        </div>
        <div class="col-sm-8">
          <input type="file" name="" (change)="addFile($event)" formControlName="additional_upload_file" id="" class="form-control" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-4">
          <label>b. Comments</label>
        </div>
        <div class="col-sm-8">
          <textarea
            name=""
            class="form-control"
            formControlName="additional_comments"
            id=""
            cols="20"
            rows="5"
          ></textarea>
        </div>
      </div>

<div class="actions p-3">
    <button class="btn btn-primary mx-2" (click)="uploadFiles(form)">Submit</button>
    <button class="btn btn-info mx-2 text-white">Save as draft</button>
</div>
    </div>
  </form>
  <ng-template #taskDetails>
    <div class="card-container row ">
      <div class="card col-sm-3 p-2 m-1">
        <h6>User details</h6>
        <div>
          <span class="fw-semibold">Names:  </span> <span>MUGISHA Amir</span> <br>
          <span class="fw-semibold">Email:  </span> <span>mugishabankz3@gmail.com</span><br>
          <span class="fw-semibold">Phone N<sup>o</sup>:  </span> <span>+250781664001</span>
        </div>
      </div>
      <div class="card col-sm-5 p-2 m-1">
        <h6>Task details</h6>
        <div>
          <div class="row">
            <div class="col-sm-6">
              <span class="fw-semibold">Task status:  </span> <span class="danger">pending</span> <br>
            </div>
            <div class="col-sm-6">
              <span class="fw-semibold">Site visit status:  </span> <span class="danger">pending</span> <br>
            </div>
            <div class="col-sm-6"></div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="fw-semibold">Priority:  </span> <span class="danger">Normal</span> <br>
            </div>
            <div class="col-sm-6">
              <span class="fw-semibold">Assigned by:  </span> <span class="danger">Keppi banks</span> <br>
            </div>
            <div class="col-sm-6"></div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <span class="fw-semibold">Task sent on:  </span> <span class="danger">14 June 2023</span> <br>
            </div>
            <div class="col-sm-6">
              <span class="fw-semibold">Assigned to:  </span> <span class="danger">Wijos banks</span> <br>
            </div>
            <div class="col-sm-6"></div>
          </div>
        </div>
      </div>
      <div class="card col-sm-3 p-2 m-1">
        <h6>Site visit details</h6>
      </div>
    </div>
  </ng-template>
</div>
